import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from '../../layouts/cssstyled';

import { fieldsValidator, processError } from '../../utils/helper';
import FormField from '../FormField';

const fields = {
  password: {
    validators: ['required', 'password'],
  },
  newpass: {
    validators: ['required', 'password'],
  },
  newpass2: {
    validators: ['required', 'password'],
  },
};

/*
this validate is different anothers forms that show error always in all fields,
here show errors fields  only on submit,  trought <Field...  showError="submitFailed"/>
 */
const validate = (values, props) => {
  let result = fieldsValidator(fields, values);
  if (Object.keys(result).length === 0) {
    if (values.newpass !== values.newpass2) {
      result.newpass2 = props.t('mobilsemError.newpassDiff');
    }
  }
  return result;
};

class ChangePass extends Component {
  render() {
    const { error, pristine, invalid, submitFailed, submitting, t } =
      this.props;
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="formSection" style={{ flexDirection: 'column' }}>
          <Field
            name="password"
            typeInput="password"
            component={FormField}
            label="login.currentpassword"
            validatorRequired="1"
            width="300px"
            showError="submitFailed"
          />
          <Field
            name="newpass"
            typeInput="password"
            component={FormField}
            label="login.newpassword"
            validatorRequired="1"
            width="300px"
            showError="submitFailed"
          />
          <Field
            name="newpass2"
            typeInput="password"
            component={FormField}
            label="login.newpassword2"
            validatorRequired="1"
            width="300px"
            showError="submitFailed"
          />
          <div className="formRow">
            <div style={{ position: 'relative' }}>
              <Button
                type="submit"
                disabled={submitting}
                style={{ paddingLeft: '35px' }}
              >
                {t('info.vUpdate')}
              </Button>
            </div>
          </div>
          <div>
            {error && <strong>{error}</strong>}

            <div className="formError">
              {invalid && submitFailed && t('form.haserrors')}
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const ChangePassForm = reduxForm({
  form: 'formChangePass',
  validate,
})(ChangePass);

export default ChangePassForm;
