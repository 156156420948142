import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { reactI18nextModule } from 'react-i18next';

let querystring = window.location.search.substring(1);
let params = new URLSearchParams(querystring);

let lang = params.get('lang');
if (!lang) {
  lang = localStorage.getItem('lang'); // language BLOCK TWIN LG0201
  if (!lang) {
    lang = 'fr';
    localStorage.setItem('lang', lang);
  }
} else {
  localStorage.setItem('lang', lang);
}
i18n
  .use(Backend)
  .use(reactI18nextModule)
  .init({
    lng: lang,
    fallbackLng: 'fr', //don't look for reemplacement translation in another language
    ns: ['translations'],
    defaultNS: 'translations',
    debug: false, // true print translations, dicctionary, etc in console
    interpolation: {
      escapeValue: false,
      format: function (value, formatting, lng) {
        //console.log('value is :', value);
        return value;
      },
    },
    react: {
      wait: true,
    },
  });

i18n.on('languageChanged', function (lng) {
  localStorage.setItem('lang', lng);
  //moment.locale(lng);
  //console.log('save language', lng);
});

i18n.on('failedLoading', (lng, ns, msg) => {
  if (lng !== 'dev') {
    alert('Dictionary Loading Error:' + lng); // First lgn='dev',ns='translations' is run automatically and give error
    //console.log('Dictionary ** error '); //console.log(lng); //console.log(ns); //console.log(msg);
  }
});

export default i18n;
