import decode from 'jwt-decode';
export const checkAuth = () => {
  const token = getSession('token');
  //console.log('token',token);
  if (!token) {
    return false;
  }

  try {
    // { exp: 12903819203 }
    // The number is the number of seconds since Jan 1 1970.
    const { exp } = decode(token);
    // console.log('token expiration', new Date(exp*1000) );
    if (exp < new Date().getTime() / 1000) {
      return false;
    }
  } catch (e) {
    return false;
  }

  return true;
};

const storageProperySession = 's'; //name of key to store session

export const getSession = (field = '') => {
  const sStored = localStorage.getItem(storageProperySession); // when there is nothing give null
  let session = {};
  if (sStored !== null) {
    session = JSON.parse(sStored);
    //console.log(session);
  }
  if (session.pe) session.pe = JSON.parse(session.pe); // need parse subelement too, that is stringify
  if (session.ar) session.ar = JSON.parse(session.ar); // need parse subelement too, that is stringify
  if (field) {
    if (!session[field]) return null; // important for field, return null, because check is with this value
    session = session[field];
  }

  return session;
};

export const saveSession = (response) => {
  localStorage.setItem(storageProperySession, JSON.stringify(response));
};

export const deleteSession = () => {
  localStorage.removeItem(storageProperySession);
};
