import React from 'react';
import { connect } from 'react-redux';
import { ISOtoDate, resolvePathObj } from '../utils/commonutils';
import { Tables } from '../defTables';

const RecordInfo = (props) => {
  if (
    !Tables[props.tableCrud].related ||
    !Tables[props.tableCrud].related.updatedUser ||
    !props.data
  ) {
    /* if table have no realated table or users related table is not defined,
     then have no info about users action
    */
    return null;
  }
  /*
  users name is recorded on main ql: props.data,
  alias for the field container = tableCrud + fieldkey = Eg: customerupdated_user_id { id, name }
  don't need id user
   */
  return (
    <div>
      <div className="formRecordInfo">
        {
          <span>
            {props.t('form.updatedAt')} {props.data.updatedAt}
          </span>
        }
        {typeof resolvePathObj(
          props,
          'data.' + props.tableCrud + 'updated_user_id.name'
        ) !== 'undefined' && (
          <span>
            {' '}
            {props.t('form.byUser')}{' '}
            {props.data[props.tableCrud + 'updated_user_id'].name}
          </span>
        )}

        {
          <span>
            , {props.t('form.createdAt')} {props.data.createdAt}
          </span>
        }
        {typeof resolvePathObj(
          props,
          'data.' + props.tableCrud + 'created_user_id.name'
        ) !== 'undefined' && (
          <span>
            {' '}
            {props.t('form.byUser')}{' '}
            {props.data[props.tableCrud + 'created_user_id'].name}
          </span>
        )}
      </div>
    </div>
  );
};

export default connect()(RecordInfo);
