import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../layouts/cssstyled';

const SignedOut = ({ t }) => (
  <div>
    <br />
    <div>{t('login.signedoutmsg')}</div>
    <br />
    <Link to="/signin" className="navbar">
      <Button>{t('login.signinagain')}</Button>
    </Link>
  </div>
);

export default SignedOut;
