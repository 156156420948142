import React, { useEffect, useState } from 'react';
import {
  resolvePathObj,
  getInputValue,
  sourcesOptions,
  getObjFromListById,
  tryParseJSON,
} from '../../utils/commonutils';
import ShowIcon from '../icons/ShowIcon';

const Comp = (props) => {
  const { t } = props;
  // console.log('formProps.containerPropsForm.crud_view_registration', props.formProps.containerPropsForm.crud_view_registration.registration);
  const room_mates = tryParseJSON(
    resolvePathObj(
      props,
      'formProps.containerPropsForm.crud_view_registration.registration._roommates',
      { notFound: '[]' }
    ),
    []
  );
  useEffect(() => {}, [
    props.formProps.containerPropsForm.crud_view_registration,
  ]);

  //console.log('room_mates', room_mates, props.formProps.containerPropsForm);
  const room_shared = resolvePathObj(
    props,
    'formProps.containerPropsForm.crud_view_registration.registration.registrationregonline_id.room_shared',
    { notFound: null }
  );
  return (
    <React.Fragment>
      <div style={{ color: '#333333' }}>
        <div style={{ marginTop: '3px' }}>
          <div className="formLabel">{t('form.room_shared_asigned')}</div>
          {room_mates.map((mate, index) => {
            // console.log('mate', mate);
            return (
              <div key={'mate' + index} style={{ display: 'flex' }}>
                <div>{mate.name}</div>
                <div style={{ width: '30px', paddingLeft: '4px' }}>
                  <a
                    onClick={() =>
                      props.formProps.containerPropsForm.goUrl(
                        '/registration-view/' + mate.registration_id
                      )
                    }
                  >
                    <ShowIcon size="18" color="gray" icon="eye" />
                  </a>
                </div>
              </div>
            );
          })}
          {room_mates.length === 0 && <div>{t('info.empty')}</div>}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Comp;
