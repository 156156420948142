import React, { Component } from 'react';

const FieldReadOnly = (props) => {
  return (
    <div
      className="formRow"
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: props.direction || 'column',
        marginBottom: 'auto',
        width: props.noWidth ? null : '300px',
      }}
    >
      <div style={{ color: '#333333', display: 'flex', alignItems: 'center' }}>
        <div>
          <label
            className="formLabel"
            style={{ display: 'inline', paddingRight: '10px' }}
          >
            {props.label}
          </label>
        </div>
      </div>
      <div
        style={{
          color: '#333333',
          display: 'flex',
          height: '37px',
          alignItems: 'center',
        }}
      >
        <div>{props.value}</div>
      </div>
    </div>
  );
};

export default FieldReadOnly;
