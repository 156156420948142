import React, { Component } from 'react';
import ShowIcon from './icons/ShowIcon';
import Portal from './Portal';
import { Button } from '../layouts/cssstyled';
class Confirm extends Component {
  static defaultProps = {
    buttons: [
      {
        label: 'Cancel',
        onClick: () => null,
      },
      {
        label: 'Confirm',
        onClick: () => null,
      },
    ],
    childrenElement: () => null,
    willUnmount: () => null,
  };

  constructor(props) {
    super(props);
  }

  handleClick = (e, id) => {
    //e.preventDefault();
    this.props.hideConfirm(id);
  };

  handleClickButton = (id, button) => {
    if (button.onClick) button.onClick();
    this.props.hideConfirm(id);
  };
  /*
                {confirmObj.text.split('\n').map((text, key) => {
                  let fontSize = '18px';
                  let color = '#777777';
                  if (text.substr(0,1) === '[') {
                    if (text.substr(1,1) === 's') {
                      fontSize = '15px';
                    }
                    if (text.substr(1,1) === 'x') {
                      fontSize = '22px';
                    }
                    if (text.substr(2,1) === 'l') { // light
                      color = '#999999';
                    }
                    if (text.substr(2,1) === 'b') { // obscure
                      color = '#333333';
                    }
                    text = text.substr(4);
                  }

                  return <div key={key} style={{minHeight: '20px', fontSize, color}}>{text}</div>;
                })}
                */

  render() {
    const confirmObj = this.props.confirm.obj;
    const buttons = confirmObj.buttons || this.defaultProps.buttons;

    let textlines = [];
    if (Array.isArray(confirmObj.text)) {
      textlines = confirmObj.text;
    } else {
      confirmObj.text.split('\n').map((text, key) => {
        let fontSize = '18px';
        let color = '#777777';
        if (text.substr(0, 1) === '[') {
          if (text.substr(1, 1) === 's') {
            fontSize = '15px';
          }
          if (text.substr(1, 1) === 'x') {
            fontSize = '22px';
          }
          if (text.substr(2, 1) === 'l') {
            // light
            color = '#999999';
          }
          if (text.substr(2, 1) === 'b') {
            // obscure
            color = '#333333';
          }
          text = text.substr(4);
        }

        textlines.push(
          <div key={key} style={{ minHeight: '20px', fontSize, color }}>
            {text}
          </div>
        );
      });
    }

    return (
      <Portal
        header={confirmObj.header}
        close={confirmObj.close || '1'}
        open="1"
        onClose={() => {
          this.props.hideConfirm(confirmObj.id);
        }}
      >
        <div style={{ marginBottom: '15px' }}>
          <a
            className="aNotHover"
            onClick={(e) => this.handleClick(e, this.props.confirm.id)}
          >
            <ShowIcon icon="close" size="19" color="#ffffff" />
          </a>
          <div>
            <div>
              <div>{textlines}</div>
            </div>
            <div style={{ marginTop: '20px' }}>
              {buttons.map((button, i) => (
                <Button
                  margin="1"
                  key={i}
                  onClick={() => this.handleClickButton(confirmObj.id, button)}
                >
                  {button.label}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </Portal>
    );
  }
}

export default Confirm;
