import React from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { bindActionCreators } from 'redux';
import Portal from '../Portal';
import { fetchQl } from '../../apolloClient';
import FormField from '../FormField';
import crudCode from './crudCode';
import { processNewValues, uuid } from '../../utils/helper';
import { connect } from 'react-redux';
import {
  processValues,
  resolvePathObj,
  getInputValue,
  sourcesOptions,
  getObjFromListById,
  realTypeOf,
} from '../../utils/commonutils';
import { Tables } from '../../defTables';
import { appSubmitStart, appSubmitStop } from '../../actions/appActions';
import { showConfirm } from '../../actions/confirmActions';

import ShowIcon from '../icons/ShowIcon';
import { Button } from '../../layouts/cssstyled';
const mainForm = 'registrationForm';
const nameForm = 'MemberAddress';
class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      joinCustomers: [], // // group and current customer included
      candidatsCustomers: [], // cantidats to grouping (related customer or same address group)
      currentCustomer: { id: '', name: '' },
      newCustomer: { id: '', name: '' },
      checks: {},
    };
  }

  componentDidMount() {
    const currentCustomer = this.getMainCustomer();
    this.updateCustomersStateFromInputCustomers(); // TWIN SC8190
    this.setState({ currentCustomer }); // just load one time currentCustomer the first time dialog is created
  }

  handleCheckChieldElement = (event) => {
    // this logic is simple, last value, overwrite existint it, from first object
    this.setState(
      {
        checks: {
          ...this.state.checks,
          [event.target.value]: event.target.checked,
        },
      },
      () => {
        // in callback when state is really updated
      }
    );
  };

  showModalGroupping = async (evt) => {
    const currentCustomer = this.getMainCustomer();
    this.updateCustomersStateFromInputCustomers(); // TWIN SC8190

    this.setState(
      {
        showModal: true,
        currentCustomer,
      },
      async () => {
        if (currentCustomer && currentCustomer.id) {
          // only if registration customer is typed
          // cherche customer associated, to show by default , in suggestion to add new customer
          // but only when it was not added
          const variables = [
            { name: 'customer_id', type: 'ID!', value: currentCustomer.id },
          ];
          const ql = `query getRelatedCustomers($customer_id: ID!) {
          getRelatedCustomers(customer_id: $customer_id) {
            id
            name
            typerelation
            direction
          }    
        }
       `;

          // send this.props , important for master resolves organizer id
          const valuesFetch = await fetchQl(ql, variables, {
            singular: true,
            props: this.props,
          });
          // console.log('valuesFetch', valuesFetch);
          // has found, normally must found becase onChange autocomplete is after customer is selected

          if (resolvePathObj(valuesFetch, 'data.getRelatedCustomers')) {
            const candidatsCustomers = [];
            valuesFetch.data.getRelatedCustomers.map((customer) => {
              // verifify that customer is not already added
              const aFound = getObjFromListById(
                this.state.joinCustomers,
                customer.id
              );
              // just add customers that is not present in  selectbox, or were already added
              if (!aFound && customer.id !== this.state.currentCustomer.id)
                candidatsCustomers.push(customer);
            });
            this.setState({ candidatsCustomers });
          }
          /*
        // not needed anymore , modify init values for the  autocomplete
        this.props.dispatch ( change ( nameForm, 'customer_id',
          {id: customerRelated.id, name: customerRelated.name}));*/
        }
      }
    );
  };

  updateCustomersStateFromInputCustomers = () => {
    const registrationgroupping_customers = getInputValue(
      this.props,
      'registrationgroupping_customers',
      { nameForm: mainForm }
    );
    if (registrationgroupping_customers) {
      const joinCustomers = JSON.parse(registrationgroupping_customers);
      this.setState({ joinCustomers });
    }
  };
  getMainCustomer = () => {
    const customer = getInputValue(this.props, 'customer_id', {
      nameForm: mainForm,
    });
    return {
      id: (customer && customer.id) || '',
      name: (customer && customer.name) || '',
    };
  };

  delAssociation = (evt) => {
    /*if (!window.confirm(`Vous êtes sur de quitter la persone de l'adresse partagé?`)) {
      return false;
    }*/
    this.props.showConfirm({
      id: uuid(),
      type: 'confirmation',
      text: this.props.t('form.uSureQuitGrouping'),
      buttons: [
        {
          label: this.props.t('form.yes'),
          onClick: () => {
            this.props.dispatch(
              change(mainForm, 'registrationgroupping_customers', null)
            );
            this.props.dispatch(change(mainForm, 'customeraddress_id', null));
          },
        },
        {
          label: this.props.t('form.no'),
          onClick: () => null,
        },
      ],
    });
  };

  executeCode = async (methodCode = '', params = {}) => {
    // add customer
    if (!params.newValue) {
      return;
    }
    const variables = [{ name: 'id', type: 'ID!', value: params.newValue }];
    const ql = `query CustomerView($id: ID!) {
      customer(id: $id) {
        id
        customeraddress_id
        name
        lastname
        firstname
        inactive
        info
        contacts
        phones
        addresses
        identitydocs
        customercustomeraddress_id  {
          customers
          addresses
        }
      }    
    }
    `;
    // send this.props , important for master resolves organizer id
    const valuesFetch = await fetchQl(ql, variables, {
      singular: true,
      props: this.props,
    });

    // has found, normally must found becase onChange autocomplete is after customer is selected

    if (resolvePathObj(valuesFetch, 'data.customer.id')) {
      let recCustomer = valuesFetch.data.customer;
      this.setState({
        newCustomer: { id: recCustomer.id, name: recCustomer.name },
      });
    }
  };

  joinToGroup = () => {
    let newsCustomers = [];
    if (this.state.newCustomer.name) {
      newsCustomers.push(this.state.newCustomer);
    }

    this.state.candidatsCustomers.map((customer, index) => {
      if (this.state.checks[customer.id]) {
        newsCustomers.push(customer);
      }
    });

    if (newsCustomers.length === 0) {
      alert('Vous devez chosir un nom avec qui se grouper');
      return false;
    }
    const found = this.state.joinCustomers.find((element) => {
      return element.id === this.state.newCustomer.id;
    });
    if (found || this.state.currentCustomer.id === this.state.newCustomer.id) {
      alert(this.props.t('info.customer_nameExist'));
      return false;
    }

    // save customer related / redux form need stringify
    const listWithJoinedCustomer = [
      ...this.state.joinCustomers,
      ...newsCustomers,
    ];
    // console.log('listWithJoinedCustomer', listWithJoinedCustomer);
    this.props.dispatch(
      change(
        mainForm,
        'registrationgroupping_customers',
        JSON.stringify(listWithJoinedCustomer)
      )
    );
    this.setState({
      joinCustomers: listWithJoinedCustomer, // import to show on the form the updated list , because is not possible update tine input form and be able to show it
      showModal: false,
      newCustomer: { id: '', name: '' }, // reset newCustomer
    });
    this.props.dispatch(change(nameForm, 'customer_id', null)); // reset autocomplet, for the next time , add a new one
  };

  closeModal = () => {
    // remove dialog name from the array list of dialog opened, in containter state
    // because need control state dialog, more one dialog can be opened at same time
    // lanunched from container,  don't support more one dialog same type
    // console.log('this.props', this.props);
    //this.props.toolbarFunctionsContainer('closeDialog', {dialogName: 'MailCompose'});
    // in case this component that is called always inside the form and inside a <Field>
    // simply use formProps
    // another case like MailCompose is called outside Form, in view or List like
    // a independent component
    this.props.formProps.toolbarFunctions('closeDialog', {
      dialogName: 'MailCompose',
    });
    // hidde modal
    this.setState({
      showModal: false,
    });
  };

  removeCustomer = (customer_id) => {
    let newCustomers = [];
    this.state.joinCustomers.map((customer) => {
      if (customer && customer.id !== customer_id) {
        newCustomers.push(customer);
      }
    });

    this.setState(
      (state) => ({
        joinCustomers: newCustomers,
      }),
      () => {
        // in callback when state is really updated
        this.props.dispatch(
          change(
            mainForm,
            'registrationgroupping_customers',
            JSON.stringify(newCustomers)
          )
        );
      }
    );
  };

  render() {
    const { showModal } = this.state;

    /* block for DISPLAY IN form CUSTOMER, NOT for Group Dialog Form
      display: Customers List

     registrationgroupping_customers, means is associated to group, it was loaded with the  record or was
     setted when pick the group
    */
    const currentCustomer = this.getMainCustomer();
    let customerFullList = [];
    customerFullList.push(<div key="one">1. {currentCustomer.name}</div>); // add always current addresses
    if (this.state.joinCustomers) {
      // to avoid duplicate, insert current customer and from the list only different customers
      this.state.joinCustomers.map((customer, index) => {
        // /TWIN ServermCT131 customer.id , by error is saving {name} and not {id, name}; ignore those wrong rows
        if (customer.id && customer.id !== currentCustomer.id)
          customerFullList.push(
            <div key={index + 1}>
              {index + 2}. {customer.name}
            </div>
          );
      });
    }

    let customerAddedList = [];
    if (!this.state.joinCustomers) {
      // is null
      customerAddedList = null;
    } else if (this.state.joinCustomers.length === 0) {
      customerAddedList = null; // <div key='noA' style= {{ display: 'flex', padding: '10px' }}>Sans Group</div>
    } else {
      this.state.joinCustomers.map((customer, key) => {
        customerAddedList.push(
          <div
            key={key}
            style={{ display: 'flex', paddingTop: '5px', alignItems: 'top' }}
          >
            <div>{customer.name}</div>
            <div>
              <a key="delete1" onClick={() => this.removeCustomer(customer.id)}>
                <ShowIcon size="20" icon="close" color="darkred" />
              </a>
            </div>
          </div>
        );
      });
    }
    const candidatsCustomers = [];
    this.state.candidatsCustomers.map((customer, index) => {
      let isChecked = false;
      if (this.state.checks[customer.id]) isChecked = true;

      candidatsCustomers.push(
        <div
          key={index}
          style={{
            display: 'flex',
            marginTop: '5px',
            backgroundColor: '#dddddd',
            alignItems: 'center',
            width: '80%',
          }}
        >
          <div style={{ width: '20px' }}>
            <input
              name={'itemcheck' + index}
              id={'itemcheck' + index}
              style={{ fontSize: '16px' }}
              type="checkbox"
              value={customer.id}
              onChange={this.handleCheckChieldElement}
              checked={isChecked}
            />
          </div>
          <div style={{ padding: '10px 10px', display: 'flex' }}>
            {customer.direction === '<' ? (
              <React.Fragment>
                <div style={{ color: '#999999' }}>{customer.typerelation}</div>
                <div>&nbsp;{customer.name}</div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div>{customer.name}&nbsp;</div>
                <div style={{ color: '#999999' }}>{customer.typerelation}</div>
              </React.Fragment>
            )}
          </div>
        </div>
      );
    });

    return (
      <div style={{}}>
        {customerAddedList && (
          <div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
              }}
            >
              <div style={{ marginLeft: '10px' }}> {customerAddedList}</div>
            </div>
          </div>
        )}

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <a onClick={(evt) => this.showModalGroupping(evt)}>
              <ShowIcon size="20" color="#4984ab" icon="link" />
            </a>
          </div>
          <div style={{ fontSize: '13px' }}>
            <a onClick={(evt) => this.showModalGroupping(evt)}>
              &nbsp;Inscription Multiple
            </a>
          </div>
        </div>

        <Portal
          header="Inscription Multiple"
          scroll="1"
          close="1"
          open={showModal}
          onClose={() =>
            this.setState({
              showModal: false,
            })
          }
          buttons={[
            { title: this.props.t('info.vAdd'), action: this.joinToGroup },
            { title: this.props.t('info.vCancel'), action: this.closeModal },
          ]}
        >
          <div>
            <div style={{ marginBottom: '15px' }}>
              <div
                style={{
                  color: '#777777',
                  fontSize: '16px',
                  marginBottom: '10px',
                }}
              >
                Déjà ajouté
              </div>
              <div style={{ color: '#444444', fontSize: '15px' }}>
                {customerFullList}
              </div>
            </div>
            <div style={{ marginBottom: '15px' }}>
              <div style={{ alignItems: 'center' }}>
                <div style={{ paddingRight: '10px', color: '#777777' }}>
                  Cochez ou saisissez le(s) nom(s) à ajouter
                </div>
                <div>{candidatsCustomers}</div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '15px',
                  }}
                >
                  <div style={{ fontsize: '14px' }}>Saisir nom:&nbsp;</div>
                  <div style={{ maxWidth: '300px' }}>
                    <Field
                      inputName="customer_id"
                      name="customer_id"
                      noMainContainer="1"
                      width="200px"
                      noLabel="1"
                      noErrors="1"
                      formProps={this.props}
                      pathInTables="registration.fields.customer_id"
                      formState={this.state}
                      nameForm={nameForm}
                      tableCrud="registration"
                      component={FormField}
                      executeCode={this.executeCode}
                      type="selectAutocomplete"
                      typeInput="selectAutocomplete"
                      disabled={this.props.id ? true : false}
                      mustOnChange={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      </div>
    );
  }
}
//
const ComponentWithData = reduxForm({
  form: nameForm, // a unique identifier for this form
  // TWIN FR141 very important , the values that are loading and changing  after form is created
  // so need reload the last variables in form
  //enableReinitialize: true,
  enableReinitialize: false,
  //validate,
})(Form);

function mapStateToProps(state, ownProps) {
  const initialValues = {};
  const statesReturn = { myState: state, initialValues };
  return statesReturn;
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { appSubmitStart, appSubmitStop, showConfirm, dispatch },
    dispatch
  );
};

const ComponentWithDataAndState = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithData);

export default ComponentWithDataAndState;
