import React from 'react';

const Icon = (props) => {
  const fill = props.fill || '#fff';

  return (
    <svg
      className="umbrella"
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      viewBox="0 0 313 252"
    >
      <path
        fill={fill}
        d="M99.170,55.413 C105.357,55.413 110.645,53.218 115.035,48.828 C119.426,44.438 121.620,39.150 121.620,32.964 C121.620,26.778 119.425,21.490 115.035,17.100 C110.645,12.709 105.357,10.515 99.170,10.515 C92.984,10.515 87.596,12.710 83.006,17.100 C78.416,21.489 76.121,26.778 76.121,32.964 C76.121,39.150 78.416,44.438 83.006,48.828 C87.596,53.218 92.984,55.413 99.170,55.413 ZM21.043,231.871 L21.043,234.865 C21.043,239.255 22.539,243.046 25.533,246.239 C28.526,249.432 32.218,251.028 36.608,251.028 C44.591,251.028 49.779,247.237 52.174,239.654 L79.713,170.952 L106.055,239.055 C106.853,242.248 108.649,245.041 111.443,247.436 C114.237,249.831 117.630,251.028 121.620,251.028 C126.011,251.028 129.702,249.432 132.696,246.239 C135.689,243.047 137.186,239.255 137.186,234.865 L137.186,231.871 L102.009,136.591 L104.407,122.279 L106.653,124.855 C107.052,126.851 107.951,128.447 109.347,129.645 C110.744,130.841 112.041,131.440 113.239,131.440 L115.035,132.039 L152.153,141.019 L153.949,141.019 C157.142,141.019 159.836,140.021 162.031,138.025 C164.226,136.030 165.324,133.635 165.324,130.841 C165.324,125.254 162.530,121.662 156.943,120.066 L124.614,112.283 L117.110,88.073 C111.041,84.546 116.082,85.697 109.751,85.011 C104.822,84.476 101.383,89.894 98.190,89.495 L88.549,90.046 C83.361,90.046 72.689,86.063 68.698,89.057 C64.706,92.050 62.277,101.910 61.080,105.103 L56.819,121.205 L21.043,231.871 L21.043,231.871 Z"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        d="M26.924,85.365 L51.915,85.365 L38.159,139.000 L13.159,139.000 L26.924,85.365 Z"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        d="M169.974,94.972 L179.003,94.972 L174.033,251.000 L165.001,251.000 L169.974,94.972 Z"
      />
    </svg>
  );
};
export default Icon;
