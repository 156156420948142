import React from 'react';
import ShowIcon from '../components/icons/ShowIcon';
import { errorTranslate, resolveLink } from '../utils/helper';
import { sourcesOptions, processValues } from '../utils/commonutils';

export const CrudNav = (props) => {
  let aElements = [];
  let pageButtons = [];
  const { page, t } = props;

  pageButtons.push(
    <a key="nav1" onClick={(e) => props.navPage('first', page)}>
      <ShowIcon
        size="32"
        color={page._totalpages > 2 && page._page > 1 ? '#5995c3' : '#CCCCCC'}
        icon="navfirst"
      />
    </a>
  );
  pageButtons.push(
    <a key="nav2" onClick={(e) => props.navPage('left', page)}>
      <ShowIcon
        size="32"
        color={page._page > 1 ? '#5995c3' : '#CCCCCC'}
        icon="navleft"
      />
    </a>
  );
  pageButtons.push(
    <span key="navpage" style={{ color: '#5995c3', fontSize: '18px' }}>
      {' '}
      {page._page} / {page._totalpages}
    </span>
  );
  pageButtons.push(
    <a key="nav3" onClick={(e) => props.navPage('right', page)}>
      <ShowIcon
        size="32"
        color={page._page < page._totalpages ? '#5995c3' : '#CCCCCC'}
        icon="navright"
      />
    </a>
  );
  pageButtons.push(
    <a key="nav4" onClick={(e) => props.navPage('last', page)}>
      <ShowIcon
        size="32"
        color={
          page._totalpages > 2 && page._page < page._totalpages
            ? '#5995c3'
            : '#CCCCCC'
        }
        icon="navlast"
      />
    </a>
  );
  pageButtons.push(
    <span key="nav14">
      {' '}
      {page._recordsperpage} {t('info.recsPerPage')} ,&nbsp;
    </span>
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#999999',
        paddingTop: '20px',
      }}
    >
      {page._totalpages > 1 && pageButtons}
      {page._totalrecords} {t('info.recordsFound')}
    </div>
  );
};
