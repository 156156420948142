import styled from 'styled-components';

const fontSize = {
  normal: '1em',
  large: '1.5em',
  small: '0.8em',
};

const DynamicDiv = styled.div`
  background: ${({ theme }) => theme.main};
`;

const getSize = (obj, rate = 1) =>
  Object.assign(
    ...Object.entries(obj).map(([k, v]) => {
      const sizeMembres = v.match(/(\d+(\.\d+)?)(\w+)/);
      // //console.log('membre size:');
      // //console.log(sizeMembres);
      return { [k]: sizeMembres[1] * rate + sizeMembres[3] };
    })
  );

const resolveSize = (props) => {
  let radio = 1;

  if (props.radio) radio = props.radio;
  const localfontSize = getSize(fontSize, radio);
  // //console.log('convertido');
  // //console.log(localfontSize );
  let size;
  if (props.size) {
    size = props.size;
  } else if (props.small) {
    size = localfontSize.small;
  } else if (props.large) {
    size = localfontSize.large;
  } else {
    size = localfontSize.normal;
  }
  return size;
};

export const Button = styled.button`
  font-size: ${(props) => resolveSize(props)};
  padding: 10px 12px;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border: ${(props) => {
    ////console.log('props button submit');
    ////console.log(props);
    let color;
    if (props.invalid) {
      color = ' solid 1px  red';
    } else {
      color = '0px';
    }
    return color;
  }};
  letter-spacing: 2px;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  margin: ${(props) => {
    let property;
    if (props.margin) {
      property = '10px';
    } else {
      property = '0px';
    }
    return property;
  }};
  background-color: ${(props) => {
    let color;
    if (props.plain) {
      color = 'transparent';
    } else {
      color = props.secondary ? props.theme.Secondary : props.theme.Primary;
      color = props.light ? props.theme.SecondaryLight : color;
      color = props.contrast ? props.theme.Contrast : color;
    }
    return color;
  }};
  color: ${(props) => {
    let color;
    if (props.plain) {
      color = props.secondary ? props.theme.Primary : props.theme.Secondary;
    } else {
      color = props.secondary
        ? props.theme.SecondaryText
        : props.theme.TextOnBackgroundPrimary;
      color = props.light ? props.theme.TextOnBackgroundSecondaryLight : color;
      color = props.contrast ? props.theme.ContrastText : color;
    }
    return color;
  }};
  &:disabled {
    color: #cccccc;
    background-color: #ffffff;
    border: solid 1px #cccccc;
  }
  &:hover {
    box-shadow:
      0 14px 28px rgba(0, 0, 0, 0.25),
      0 5px 5px rgba(0, 0, 0, 0.22);
    background-color: ${(props) => {
      let color;
      if (props.plain) {
        color = 'transparent';
      } else if (!props.disabled) {
        color = props.contrast
          ? props.theme.ContrastSuperDark
          : props.theme.PrimarySuperDark;
      }
      return color;
    }};
  }
`;

export const MenuOption = styled.div`
  font-size: ${(props) => resolveSize(props)};
  padding: 0.25em 1em;
  border: none;
  border-radius: 3px;
  background-color: ${(props) => {
    let color;
    if (props.raised) {
      color = props.secondary ? props.theme.Secondary : props.theme.Primary;
    } else {
      color = 'transparent';
    }
    return color;
  }};
  color: ${(props) => {
    let color;
    if (props.plain) {
      color = props.theme.SectionBackground;
    } else {
      color = props.theme.PrimaryText;
    }
    return color;
  }};
`;

export const SectionContent = styled.div`
  background-color: ${(props) => props.theme.Background};
`;

export const SectionMenu = styled.div`
  background-color: ${(props) => props.theme.BackgroundLight};
`;
