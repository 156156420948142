// commment test 2
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Field, arraySwap, FieldArray } from 'redux-form';

import ShowIcon from './icons/ShowIcon';
import { uuid, errorTranslate } from '../utils/helper';
import {
  getOptions,
  sourcesOptions,
  resolvePathObj,
  //transMsg as t,
  getObjFromListById,
  realTypeOf,
} from '../utils/commonutils';
import { normalizes } from '../utils/normalizes';
import { Tables } from '../defTables';
import FormField from './FormField';

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <label>{label}</label>
    <div>
      <input {...input} type={type} placeholder={label} />
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

const defaultAddValues = (fieldParentKey, valuesFields = {}) => {
  let defValues = { id: uuid(), ...valuesFields };
  if (fieldParentKey === 'addresses' || fieldParentKey === 'identitydocs') {
    defValues.country = 'FRA'; // %% Hardcode country FRANCE
    //     defValues.city =''; // important autosuggest give no errors because undefined value
  }
  ////console.error(fieldParentKey);
  // //console.log(defValues);
  return defValues;
};

class FormArrayField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insertedRow: false, // wokrs with insertRowOnAdd
    };
  }

  render() {
    const {
      nameForm,
      tableCrud,
      fieldParentKey,
      fields: rowsObjInmutable,
      meta: { error, submitFailed },
      t,
      ...props
    } = this.props;
    let log = false;
    let field;
    let fieldGrandParentKey;
    if (this.props.fieldGrandParentKey) {
      fieldGrandParentKey = this.props.fieldGrandParentKey;
      field =
        Tables[tableCrud].fields[fieldGrandParentKey].subfields[fieldParentKey];
      // log = true;
    } else {
      if (props.pathInObject) {
        field = props.pathInObject;
      } else {
        field = Tables[tableCrud].fields[fieldParentKey];
      }
    }

    // state actual form
    let rowsValueState = rowsObjInmutable.getAll();
    let xfields;
    // type 'object Undefined' is valid, empty object can be have that value
    if (realTypeOf(rowsValueState) === '[object Object]') {
      // twin OAR143
      console.error(
        'Mobilsem ERROR 2: subfields are not Array ' +
          realTypeOf(rowsValueState) +
          ', in: tableName:' +
          tableCrud +
          ', field:' +
          fieldParentKey
      );
      rowsValueState = [];
      xfields = [];
    } else {
      xfields = Object.assign({}, rowsObjInmutable);
    }
    if (log) {
      console.log(
        `FormArrayField: subFields process, table:${tableCrud} field:${fieldParentKey} ,row values of json:`,
        rowsValueState
      );
      console.log('FormArrayField: props:', props);
    }

    let existingData = [];
    if (props.formProps.data && props.formProps.data[fieldParentKey]) {
      existingData = JSON.parse(props.formProps.data[fieldParentKey]);
      if (log) console.log('existing Data', existingData);
    }

    if (Tables[tableCrud] && Tables[tableCrud].readOnly) {
      props.mode.disabledDelete = true;
      props.mode.disabledAdd = true;
    }
    if (props.mode.insertMatchAllValues) {
      //if (props.formState.crudAction === 'Add') {

      //if (xfields.length === 0)  {
      // TWIN IM323
      let nameField = props.mode.insertMatchAllValues.field;
      let sourceField = field.subfields[nameField];
      let sourceName = sourceField.listSource;
      if (sourceName) {
        sourcesOptions[sourceName].map((list) => {
          let siAdd = true;
          let searched = { option: list.id };
          /*console.log('rowsValueState',rowsValueState);
              console.log('list',list);*/
          // in add new group, rowsValueState is undefined, has not previous value
          let objrowValueState =
            typeof rowsValueState === 'undefined'
              ? undefined
              : getObjFromListById(rowsValueState, { option: list.id }, true);
          if (log)
            console.log('search id: ' + list.id + ',result:', objrowValueState);

          if (typeof objrowValueState !== 'undefined') siAdd = false;

          // check if value to add , is accord to rules, rules are normally on a column in source
          // array. Example:  sourcesOptions.table = { disabledPermissionOption: ... }
          //                  against: insertMatchAllValues: {field: 'option',  si: { disabledPermissionOption: false }},
          if (siAdd && props.mode.insertMatchAllValues.si) {
            let rule = props.mode.insertMatchAllValues.si;
            //console.log('rule',rule);
            //console.log('list',list);
            Object.keys(rule).forEach((key) => {
              if (rule[key] !== list.mode[key]) siAdd = false;
            });
          }
          if (siAdd) {
            xfields.push(
              // %% give error render transition,, move to initialvalues form
              defaultAddValues(fieldParentKey, {
                [nameField]: {
                  id: list.id,
                  name: sourceField.translateDisabledSource
                    ? list.name
                    : t(`${sourceName}.${list.name}`),
                },
              })
            );
          }
        });
        //}
        //}
      } else {
        // is update or view, check if is ok
      }
    }
    if (props.formState.actionsave === 'add' && !this.state.insertedRow) {
      if (props.mode.insertRowOnAdd && xfields.length === 0) {
        // insert empty line
        xfields.push(defaultAddValues(fieldParentKey)); // %% give error render transition,, move to initialvalues form
        if (!props.mode.minimalRows) {
          // only with minimalRows, persits to add always first row
          setTimeout(() => {
            this.setState({ insertedRow: true });
          }, 1000);
        }
      }
    }

    let aElements = {};
    let aSubToolBar = {};
    if (log) console.log('xfiels typeof xfiled', typeof xfields, xfields);
    if (log) console.log('props', props);
    const aSubFields = {}; // allow only one subfields by all the row
    xfields.map((rowValues, index) => {
      /*
      get the real value of row, if exist (normalment yes, at leas there is a new empty row)
       */
      let rowStateValue = rowsObjInmutable.get(index);
      if (log) console.log('rowStateValue', rowStateValue);

      /*
      get the real value of existing file , accord rowStateValue
      */
      let originalExistingValueRow;
      if (rowStateValue)
        originalExistingValueRow = getObjFromListById(
          existingData,
          rowStateValue.id,
          true
        );
      // look for id, and not for index, because index is variable and not stable  because of click on delete change index original order
      //if (typeof originalExistingValueRow === 'undefined') originalExistingValueRow  = false;
      if (log)
        console.log('originalExistingValueRow', originalExistingValueRow);

      let ok = true;
      if (props.mode.insertMatchAllValues) {
        let nameField = props.mode.insertMatchAllValues.field;
        let sourceField = field.subfields[nameField];
        let sourceName = sourceField.listSource;
        if (log)
          console.log(
            'nameField:' +
              nameField +
              ', sourceName: ' +
              sourceName +
              ', sourceField:',
            sourceField
          );

        if (
          originalExistingValueRow &&
          props.formState.actionsave === 'update'
        ) {
          // TWIN IM323
          let id = originalExistingValueRow[nameField].id; // Eg result: 'payment'
          let obj = getObjFromListById(sourcesOptions[sourceName], id, true);
          if (log) console.log('id serached: ' + id + ' obj found:', obj);
          // if existing row, does match with updated source, then deleted
          if (typeof obj === 'undefined') {
            ok = false;
          }
        }
      }

      if (ok) {
        //if (log)

        //console.log('index:'+index,rowStateValue);
        aElements[index] = [];
        aSubToolBar[index] = [];

        /*if (!props.mode.disabledDelete
          && !(originalExistingValueRow && (originalExistingValueRow.disabledRowUpdate || props.mode.disabledExistingDelete))
          && !((props.mode.disabledRowInputsOnUpdate && props.mode.disabledRowDeleteOnUpdate )  && props.formState.crudAction !== 'Add' )
          ) {*/

        const aSubButtons = [];

        if (
          !props.mode.disabledDelete &&
          !(
            originalExistingValueRow &&
            (originalExistingValueRow.disabledRowDelete ||
              props.mode.disabledExistingDelete)
          ) &&
          !(
            props.mode.disabledRowInputsOnUpdate &&
            props.mode.disabledRowDeleteOnUpdate &&
            props.formState.actionsave === 'update'
          )
        ) {
          aSubButtons.push(
            <a
              key="remove"
              onClick={() => {
                props.executeCode('onChangeInput', {
                  nameForm,
                  props,
                  formProps: props.formProps,
                  parentField: fieldParentKey, // remove item,, need only parentField name, there is not inputName
                  line: index,
                  action: 'delete',
                });
                rowsObjInmutable.remove(index);
              }}
            >
              <ShowIcon
                size={fieldGrandParentKey ? 20 : 25}
                color="#d08e8e"
                icon="removecircleblack"
              />
            </a>
          );
        } else {
          aSubButtons.push(
            <ShowIcon
              key="ri"
              size={fieldGrandParentKey ? 20 : 25}
              color="#DDDDDD"
              icon="removecircleblack"
            />
          );
        }

        let fieldSwap;
        if (fieldGrandParentKey) {
          fieldSwap = `${fieldGrandParentKey}[${index}]${fieldParentKey}`;
        } else {
          fieldSwap = fieldParentKey;
        }

        if (index > 0 && !props.mode.disabledUpdate) {
          aSubButtons.push(
            <a
              key="up"
              onClick={() => {
                props.formProps.dispatch(
                  arraySwap(props.formProps.form, fieldSwap, index, index - 1)
                );
              }}
            >
              <ShowIcon
                size={fieldGrandParentKey ? 20 : 25}
                color="gray"
                icon="up"
              />
            </a>
          );
        }
        if (index < xfields.length - 1 && !props.mode.disabledUpdate) {
          aSubButtons.push(
            <a
              key="down"
              onClick={() => {
                props.formProps.dispatch(
                  arraySwap(props.formProps.form, fieldSwap, index, index + 1)
                );
              }}
            >
              <ShowIcon
                size={fieldGrandParentKey ? 20 : 25}
                color="gray"
                icon="down"
              />
            </a>
          );
        }

        aSubToolBar[index] = <div key="butt">{aSubButtons}</div>;

        let fieldsHiddenOrDisable = '';

        Object.keys(field.subfields).map((fieldKey, subindex) => {
          let inputName = rowValues + '.' + fieldKey;
          const subfield = field.subfields[fieldKey];

          // this name (without special characters) is used to ctrl state: hidden, disable, warning,
          const inputFullNameCtrl = fieldParentKey + fieldKey + index;

          {
            /* nameForm is important for getValue() autocomplete in form*/
          }
          {
            /* width="270px" , deleted, give problems: without spaces between fields */
          }
          // warning , don't take ".type" is definition for dbtype and graphql,
          // typeInput is valida for type of form
          let inputProps = {
            typeInput: subfield.typeInput ? subfield.typeInput : 'text', // this value default will be overwriten if input must be hidden
            type: subfield.typeInput ? subfield.typeInput : 'text', // need to repeat; otherwise this property is lost in FormField.js
          };
          // Warning send options in inputProps, I don't know why is not readed in FormField.js
          // anywal all is resolved in FormField.js // TWIN OPT2234

          if (subfield.onChange || subfield.onDebounce) {
            // dont' support yet real debounced for text inputs; autocomplete is like debounced
            inputProps.onChange = (event, newValue, previousValue) => {
              props.executeCode('onChangeInput', {
                nameForm,
                props,
                formProps: props.formProps,
                parentField: fieldParentKey,
                line: index,
                inputName: fieldKey,
                inputFullName: fieldParentKey + fieldKey,
                event,
                newValue,
                previousValue,
              });
              //otherProps.onChangeInput ( 'amount',event, newValue, previousValue );
            };
          }

          /*
          dont use anymore .formState.crudAction === 'Add' / 'Update', falsy with duplicate form
          more secure  props.formState.actionsave === 'update' / 'add'
           */
          /* discontinued:  normal control disable for disabledOnAdd, disabledOnUpdate is did on FormField

          if (subfield.disabledOnAdd &&  props.formState.actionsave === 'add') {
            console.log(fieldKey +' fieldarray disabled');
            inputProps.disabled = true;
          } else if (subfield.disabledOnUpdate && props.formState.actionsave === 'update') {
            inputProps.disabled = true;
          } else
          */

          /*
            .onDisable is needed for FieldArray but not for FormField, because generator create a prop for
                      fields marked '.onDisable' directly on ...Form.js, because it's faster %%%
                      then don't need control on FormField
                      but for FormArrayField it's needed.
            <Field
              disabled={
                this.props.formState.disabledFields &&  this.props.formState.disabledFields.tourroom_id
              }
            />

           */
          if (subfield.onDisable) {
            // means if this field can be disabled, only on that case control
            inputProps.disabled =
              props.formState.state.disabledFields &&
              props.formState.state.disabledFields[inputFullNameCtrl];
            /*props.executeCode(
                'onDisabled', {
                  inputFullNameWithLine: fieldParentKey +fieldKey+  index, formState: props.formState, formProps: props.formProps
                });*/
          }
          // disabled existing rows in update
          if (existingData && index + 1 <= existingData.length) {
            //
            //console.log('typeof existingData[index].disabledRowUpdate',typeof existingData[index].disabledRowUpdate);
            if (
              props.mode.disabledExistingUpdate ||
              (props.mode.disabledRowInputsOnUpdate ===
                props.formState.actionsave) ===
                'update'
            ) {
              // defined in generasorce.js, enough is existing record
              inputProps.disabled = true;
            }
            // look row for originalExistingRow that match with the rowState
            if (rowStateValue) {
              //console.log('originalExistingValueRow',originalExistingValueRow);
              // look for id, and not for index, because index is variable and not stable  because of click on delete change index original order
              // importante control undefined, because value can be boolean, need to enter if is false or true
              if (
                originalExistingValueRow &&
                typeof originalExistingValueRow.disabledRowUpdate !==
                  'undefined' &&
                ((typeof originalExistingValueRow.disabledRowUpdate ===
                  'boolean' &&
                  originalExistingValueRow.disabledRowUpdate) ||
                  (typeof originalExistingValueRow.disabledRowUpdate !==
                    'boolean' &&
                    !originalExistingValueRow.disabledRowUpdate.includes(
                      fieldKey
                    )))
              ) {
                // defined in resolver server, don't let update used record, normally control if record was used
                if (log)
                  console.log(
                    'disable data index:' +
                      index +
                      ', length existingData:' +
                      existingData.length
                  );
                inputProps.disabled = true;
              }
            }
          }
          if (
            props.mode.disabledFieldUpdates &&
            props.mode.disabledFieldUpdates.includes(fieldKey)
          ) {
            inputProps.disabled = true;
          }

          if (subfield.normalize) {
            // TWIN PRI112
            inputProps.normalize = normalizes[subfield.normalize];
          }
          if (subfield.onHidden) {
            //TWIN ONH021
            inputProps.hidden =
              props.formState.hiddenFields &&
              props.formState.hiddenFields[inputFullNameCtrl] === true
                ? true
                : false;
            if (subfield.listState) {
              inputProps.listOptions =
                props.formState.hiddenFields[inputFullNameCtrl];
            }

            /*props.executeCode(
                'onHidden',
                {
                  inputFullNameWithLine: fieldParentKey + fieldKey+ index, formState: props.formState, formProps: props.formProps
                });*/
            if (log)
              console.log(
                'result for fieldKey:' +
                  fieldParentKey +
                  fieldKey +
                  index +
                  ' ,executeCode(onHidden, inputProps.hidden:',
                inputProps.hidden
              );
          }
          // only modify type in the case of is hidden,becase before was defined 'inputProps' with key 'type' and 'typeInput' real
          // accord input object
          /*

          // discontinued block: hiddenOnAdd  and  hiddenOnUpdate now is controlled on FormField.js
          //     reading the sourceFiled props

          if (inputProps.hidden
           || (subfield.hiddenOnAdd && props.formState.xcrudAction === 'Add' )
           || (subfield.hiddenOnUpdate && props.formState.xcrudAction === 'Update')
           ) {  // twin HID565
           inputProps.typeInput ='hidden'; // hard code so input really becomes hidden
           inputProps.type ='hidden'; // hard code so input really becomes hidden
          }
          // %%% not working.. for hidden or disable deactivate validators, store index and name subfield
          if (inputProps.type ==='hidden' || inputProps.disabled) {
             fieldsHiddenOrDisable += ","+index+":"+fieldKey;
          }*/

          if (log)
            console.log(
              'fieldKey:' +
                fieldParentKey +
                '.' +
                fieldKey +
                '.' +
                index +
                ', inputProps:',
              inputProps
            );

          if (subfield.formNewSection) {
            aElements[index].push(
              <div
                key={'separator' + index + '-' + subindex}
                className="formSectionSeparator"
              />
            );
          }

          if (subfield.subfields) {
            // grand child fields
            // on the first level recursive grandchildrens, has no fieldGrandParentKey, just fieldParentKey that is sent like fieldParentKey
            // on the first level recursive grandchildrens, has fieldGrandParentKey, it's really the last level, has no .subfields
            // there is not yet grand grand childrens
            // important, to send, 'lineParent', with that we know we are in a grandchild field
            aSubFields[index] = (
              <div
                className="formSection"
                style={{ marginLeft: '40px', borderBottom: '1px' }}
                key={index + '-' + subindex + '-subfields' + fieldKey}
              >
                <FieldArray
                  formProps={this.props.formProps}
                  formState={this.props.formState}
                  pathInTables={
                    tableCrud +
                    '.fields.' +
                    fieldParentKey +
                    '.subfields.' +
                    fieldKey
                  }
                  nameForm={nameForm}
                  fieldParentKey={fieldKey}
                  lineParent={index}
                  fieldGrandParentKey={fieldParentKey}
                  inputName={fieldKey}
                  name={`${fieldParentKey}[${index}].${fieldKey}`}
                  component={FormArrayField}
                  tableCrud={tableCrud}
                  executeCode={this.props.executeCode}
                  syncCode={this.props.syncCode}
                  t={t}
                  mode={{}}
                />
              </div>
            );
          } else {
            // for  subfields   contains field riect (no  groupe of field or subfields)

            let labelField;
            // label for grandchild subfield is sent directo from here, with 'form.xxx', because generator dont touche grandchilds to fill automatic labels
            if (fieldGrandParentKey) {
              // grand child, show always labe, on the future %%% see if use .labelChildsShow logic
              labelField = subfield.label ? subfield.label : 'form.' + fieldKey;
            } else {
              labelField = field.labelChildsShow ? subfield.label : '';
            }
            let showField = true;
            if (subfield.conditional) {
              // conditional for arrayform is an array, and not a string like direct field
              showField = resolvePathObj(this.props, subfield.conditional[0], {
                compare: subfield.conditional[1],
                notFound: false,
              });
              // console.log('subfield.conditional', subfield.conditional, 'showField', showField);
            }
            if (showField) {
              if (fieldGrandParentKey) {
                // console.log('fieldGrandParentKey', fieldGrandParentKey , props.lineParent, inputFullNameCtrl);
              }
              // inputFullNameCtrl, have a rare configuration, grand parent then row number grand parent,
              // then child, and last char is row number child
              // Example: seatingconfiguration0seatingrangerange0
              //          seatingconfiguration  = grand parent
              //                              0 = row grand parent
              //                                seatingrange = child
              //                                            range = grand child
              //                                                  0 = row child
              let pathInTables;
              let pathInObject;
              if (props.pathInObject) {
                // in the case object, send direct the sourcefield
                pathInObject = props.pathInObject.subfields[fieldKey];
              } else {
                pathInTables =
                  tableCrud +
                  '.fields.' +
                  (fieldGrandParentKey
                    ? fieldGrandParentKey +
                      '.subfields.' +
                      fieldParentKey +
                      '.subfields.' +
                      fieldKey
                    : fieldParentKey + '.subfields.' + fieldKey);
              }
              aElements[index].push(
                <Field
                  key={index + '-' + subindex + '-' + fieldKey}
                  inputFullNameCtrl={
                    (fieldGrandParentKey
                      ? `${fieldGrandParentKey}${props.lineParent}`
                      : '') + inputFullNameCtrl
                  }
                  inputName={inputName}
                  name={inputName}
                  pathInTables={pathInTables}
                  pathInObject={pathInObject}
                  executeCode={props.executeCode}
                  syncCode={props.syncCode}
                  fieldParentKey={fieldParentKey}
                  fieldOnlyName={fieldKey}
                  {...inputProps}
                  component={FormField}
                  isSubfield="1"
                  label={labelField}
                  placeHolder={
                    field.placeholderChildsShow && !subfield.disabledPlaceHolder
                      ? field.label
                      : ''
                  }
                  listSource={subfield.listSource ? subfield.listSource : ''}
                  listOptions={
                    subfield.listOptions ? subfield.listOptions : null
                  }
                  index={subindex + 1}
                  icon={subfield.icon}
                  nameForm={nameForm}
                  rowsObjInmutable={rowsObjInmutable}
                  formProps={props.formProps}
                  formState={props.formState}
                  preComponentSet={
                    props.preComponentsSet &&
                    props.preComponentsSet[fieldParentKey + fieldKey]
                      ? {
                          position:
                            props.preComponentsSet[fieldParentKey + fieldKey]
                              .position,
                        }
                      : null
                  }
                  preComponent={
                    props.preComponents &&
                    props.preComponents[fieldParentKey + fieldKey]
                      ? props.preComponents[fieldParentKey + fieldKey]
                      : null
                  }
                />
              );
            }
          }
        });

        // field control hidden by line, contrls if input is hidden or disable

        /*
        bad idea ... to control disable input, can't change state on render
        aElements[index].push (
          <Field
            key={index + 'ctrl'}
            inputName="control"
            name="control"
            pathInTables={tableCrud + '.fields.' + fieldParentKey + '.subfields.' + "control"}
            type="hidden"
            component={FormField}
            isSubfield="1"
            index={1000}
            nameForm={nameForm}
            formProps={props.formProps}
          />);
        props.formProps.change(fieldParentKey+'['+index+'].control', fieldsHiddenOrDisable);*/
      }
    });

    if (props.formState.actionsave === 'add' && field.disabledAdd) {
      return (
        <div>
          <div className="appBodySubTitle">
            <div
              className="titleSectionForm"
              style={fieldGrandParentKey ? { fontSize: '16px' } : null}
            >
              {t(field.label ? field.label : `form.${fieldParentKey}`)}
            </div>
          </div>
          <div style={{ color: '#969696', fontSize: '16px' }}>
            {field.disabledMsgAdd && <span>{t(field.disabledMsgAdd)}</span>}
          </div>
        </div>
      );
    }
    const PreComponent = props.preComponent;
    let InsideAfterTitleComponent;
    let InsideUnderTitleComponent;

    if (props.preComponent) {
      if (props.preComponentSet.length > 0) {
        // is array, search position
        props.preComponentSet.map((ele, index) => {
          if (ele.position === 'insideAfterTitle') {
            InsideAfterTitleComponent = props.preComponent[index];
          }
          if (ele.position === 'insideUnderTitle') {
            InsideUnderTitleComponent = props.preComponent[index];
          }
        });
      } else if (props.preComponentSet.position === 'insideAfterTitle') {
        InsideAfterTitleComponent = props.preComponent;
      } else if (props.preComponentSet.position === 'insideUnderTitle') {
        InsideUnderTitleComponent = props.preComponent;
      }
    }

    let addButton;
    if (
      !props.mode.disabledAdd &&
      !(
        props.mode.disabledRowAddOnUpdate &&
        props.formState.actionsave !== 'add'
      )
    ) {
      addButton = (
        <div>
          <a
            onClick={() => {
              props.executeCode('onChangeInput', {
                nameForm,
                props,
                formProps: props.formProps,
                parentField: fieldParentKey, // add item,, need only parentField name, there is not inputName
                grandparentField: fieldGrandParentKey,
                action: 'addLine',
              });
              rowsObjInmutable.push(defaultAddValues(fieldParentKey));
            }}
          >
            <ShowIcon
              size={fieldGrandParentKey ? 20 : 25}
              color="#4984ab"
              icon="addcircleoutline"
            />
          </a>
        </div>
      );
    } else {
      addButton = null;
    }

    return (
      <div style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <div
            className="titleSectionForm"
            style={
              fieldGrandParentKey
                ? { fontSize: '17px', color: '#808080' }
                : null
            }
          >
            {t(field.label ? field.label : `form.${fieldParentKey}`)}
          </div>
          {addButton}
          {InsideAfterTitleComponent && (
            <InsideAfterTitleComponent {...props} t={t} />
          )}
        </div>

        {error && (
          <div key="msgerror" className="formError">
            <span>{errorTranslate(error, t)}</span>
          </div>
        )}
        {field.sublabel && (
          <div key="sublabel" style={{ fontSize: '15px', color: '#666666' }}>
            {t(field.sublabel)}
          </div>
        )}
        {InsideUnderTitleComponent && (
          <InsideUnderTitleComponent {...props} t={t} />
        )}

        {rowsObjInmutable.map((rowValues, index) => (
          <div key={'inmu' + index}>
            <div key="titleRow" className="appBodySubTitle">
              {!props.mode.disabledEnum && (
                <div
                  key="enum"
                  style={{
                    marginTop: '3px',
                    fontSize: fieldGrandParentKey ? '17px' : '18px',
                    color: fieldGrandParentKey ? '#aaaaaa' : '#676666',
                  }}
                >
                  {field.indexLabel
                    ? t(field.indexLabel) + ' ' + (index + 1)
                    : index + 1 + '°'}
                </div>
              )}
              {aSubToolBar[index]}
            </div>
            <div key="titleSubSec" className="formSubSection">
              {aElements[index]}
            </div>
            {aSubFields[index] && (
              <div className="formSubSection">{aSubFields[index]}</div>
            )}
          </div>
        ))}
        {rowsObjInmutable.length > 0 && !this.props.noFooter && (
          <div>
            <div style={{ padding: '10px 0px 10px 0px' }}>{addButton}</div>
            <div
              style={{ padding: '2px', borderBottom: 'dashed 1px #aaaaaa' }}
            ></div>
            {!fieldGrandParentKey && (
              <div
                style={{ padding: '1px', borderBottom: 'dashed 1px #aaaaaa' }}
              ></div>
            )}
            <div style={{ padding: '5px 0px 5px 0px' }}></div>
          </div>
        )}
      </div>
    );
  }
}

export default translate('translations')(FormArrayField); // give 't' = translator environment and variable
