const phonesByPrefix = {
  '+93': 'AFG',
  '+355': 'ALB',
  '+49': 'DEU',
  '+213': 'DZA',
  '+376': 'AND',
  '+244': 'AGO',
  '+1 264': 'AIA',
  '+672': 'ATA',
  '+1 268': 'ATG',
  '+599': 'ANT',
  '+966': 'SAU',
  '+54': 'ARG',
  '+374': 'ARM',
  '+297': 'ABW',
  '+61': 'CCK',
  '+43': 'AUT',
  '+994': 'AZE',
  '+32': 'BEL',
  '+1 242': 'BHS',
  '+973': 'BHR',
  '+880': 'BGD',
  '+1 246': 'BRB',
  '+501': 'BLZ',
  '+229': 'BEN',
  '+975': 'BTN',
  '+375': 'BLR',
  '+95': 'MMR',
  '+591': 'BOL',
  '+387': 'BIH',
  '+267': 'BWA',
  '+55': 'BRA',
  '+673': 'BRN',
  '+359': 'BGR',
  '+226': 'BFA',
  '+257': 'BDI',
  '+238': 'CPV',
  '+855': 'KHM',
  '+237': 'CMR',
  '+1': 'PRI',
  '+235': 'TCD',
  '+56': 'CHL',
  '+86': 'CHN',
  '+357': 'CYP',
  '+39': 'ITA',
  '+57': 'COL',
  '+269': 'COM',
  '+243': 'COD',
  '+850': 'PRK',
  '+82': 'KOR',
  '+225': 'CIV',
  '+506': 'CRI',
  '+385': 'HRV',
  '+53': 'CUB',
  '+45': 'DNK',
  '+1 767': 'DMA',
  '+593': 'ECU',
  '+20': 'EGY',
  '+503': 'SLV',
  '+971': 'ARE',
  '+291': 'ERI',
  '+421': 'SVK',
  '+386': 'SVN',
  '+34': 'ESP',
  '+372': 'EST',
  '+251': 'ETH',
  '+63': 'PHL',
  '+358': 'FIN',
  '+679': 'FJI',
  '+33': 'FRA',
  '+241': 'GAB',
  '+220': 'GMB',
  '+995': 'GEO',
  '+233': 'GHA',
  '+350': 'GIB',
  '+1 473': 'GRD',
  '+30': 'GRC',
  '+299': 'GRL',
  '+': 'ATF',
  '+1 671': 'GUM',
  '+502': 'GTM',
  '+224': 'GIN',
  '+240': 'GNQ',
  '+245': 'GNB',
  '+592': 'GUY',
  '+509': 'HTI',
  '+504': 'HND',
  '+852': 'HKG',
  '+36': 'HUN',
  '+91': 'IND',
  '+62': 'IDN',
  '+98': 'IRN',
  '+964': 'IRQ',
  '+353': 'IRL',
  '+44': 'GBR',
  '+354': 'ISL',
  '+1 441': 'BMU',
  '+1 345': 'CYM',
  '+682': 'COK',
  '+298': 'FRO',
  '+960': 'MDV',
  '+500': 'FLK',
  '+1 670': 'MNP',
  '+692': 'MHL',
  '+870': 'PCN',
  '+677': 'SLB',
  '+1 649': 'TCA',
  '+1 284': 'VG',
  '+1 340': 'VIR',
  '+972': 'ISR',
  '+1 876': 'JAM',
  '+81': 'JPN',
  '+962': 'JOR',
  '+7': 'RUS',
  '+254': 'KEN',
  '+996': 'KGZ',
  '+686': 'KIR',
  '+965': 'KWT',
  '+961': 'LBN',
  '+856': 'LAO',
  '+266': 'LSO',
  '+371': 'LVA',
  '+231': 'LBR',
  '+218': 'LBY',
  '+423': 'LIE',
  '+370': 'LTU',
  '+352': 'LUX',
  '+52': 'MEX',
  '+377': 'MCO',
  '+853': 'MAC',
  '+389': 'MKD',
  '+261': 'MDG',
  '+60': 'MYS',
  '+265': 'MWI',
  '+223': 'MLI',
  '+356': 'MLT',
  '+212': 'MAR',
  '+230': 'MUS',
  '+222': 'MRT',
  '+262': 'MYT',
  '+691': 'FSM',
  '+373': 'MDA',
  '+976': 'MNG',
  '+382': 'MNE',
  '+1 664': 'MSR',
  '+258': 'MOZ',
  '+264': 'NAM',
  '+674': 'NRU',
  '+977': 'NPL',
  '+505': 'NIC',
  '+227': 'NER',
  '+234': 'NGA',
  '+683': 'NIU',
  '+47': 'NOR',
  '+687': 'NCL',
  '+64': 'NZL',
  '+968': 'OMN',
  '+31': 'NLD',
  '+92': 'PAK',
  '+680': 'PLW',
  '+507': 'PAN',
  '+675': 'PNG',
  '+595': 'PRY',
  '+51': 'PER',
  '+689': 'PYF',
  '+48': 'POL',
  '+351': 'PRT',
  '+974': 'QAT',
  '+236': 'CAF',
  '+420': 'CZE',
  '+1 809': 'DOM',
  '+250': 'RWA',
  '+40': 'ROU',
  '+685': 'WSM',
  '+1 684': 'ASM',
  '+590': 'BLM',
  '+1 869': 'KNA',
  '+378': 'SMR',
  '+1 599': 'MAF',
  '+508': 'SPM',
  '+1 784': 'VCT',
  '+290': 'SHN',
  '+1 758': 'LCA',
  '+239': 'STP',
  '+221': 'SEN',
  '+381': 'SRB',
  '+248': 'SYC',
  '+232': 'SLE',
  '+65': 'SGP',
  '+963': 'SYR',
  '+252': 'SOM',
  '+94': 'LKA',
  '+27': 'ZAF',
  '+249': 'SDN',
  '+46': 'SWE',
  '+41': 'CHE',
  '+597': 'SUR',
  '+268': 'SWZ',
  '+992': 'TJK',
  '+66': 'THA',
  '+886': 'TWN',
  '+255': 'TZA',
  '+670': 'TLS',
  '+228': 'TGO',
  '+690': 'TKL',
  '+676': 'TON',
  '+1 868': 'TTO',
  '+216': 'TUN',
  '+993': 'TKM',
  '+90': 'TUR',
  '+688': 'TUV',
  '+380': 'UKR',
  '+256': 'UGA',
  '+598': 'URY',
  '+998': 'UZB',
  '+678': 'VUT',
  '+58': 'VEN',
  '+84': 'VNM',
  '+681': 'WLF',
  '+967': 'YEM',
  '+253': 'DJI',
  '+260': 'ZMB',
  '+263': 'ZWE',
};
const phoneByISO3 = {
  AFG: '+93',
  ALB: '+355',
  DEU: '+49',
  DZA: '+213',
  AND: '+376',
  AGO: '+244',
  AIA: '+1 264',
  ATA: '+672',
  ATG: '+1 268',
  ANT: '+599',
  SAU: '+966',
  ARG: '+54',
  ARM: '+374',
  ABW: '+297',
  AUS: '+61',
  AUT: '+43',
  AZE: '+994',
  BEL: '+32',
  BHS: '+1 242',
  BHR: '+973',
  BGD: '+880',
  BRB: '+1 246',
  BLZ: '+501',
  BEN: '+229',
  BTN: '+975',
  BLR: '+375',
  MMR: '+95',
  BOL: '+591',
  BIH: '+387',
  BWA: '+267',
  BRA: '+55',
  BRN: '+673',
  BGR: '+359',
  BFA: '+226',
  BDI: '+257',
  CPV: '+238',
  KHM: '+855',
  CMR: '+237',
  CAN: '+1',
  TCD: '+235',
  CHL: '+56',
  CHN: '+86',
  CYP: '+357',
  VAT: '+39',
  COL: '+57',
  COM: '+269',
  COD: '+243',
  PRK: '+850',
  KOR: '+82',
  CIV: '+225',
  CRI: '+506',
  HRV: '+385',
  CUB: '+53',
  DNK: '+45',
  DMA: '+1 767',
  ECU: '+593',
  EGY: '+20',
  SLV: '+503',
  ARE: '+971',
  ERI: '+291',
  SVK: '+421',
  SVN: '+386',
  ESP: '+34',
  USA: '+1',
  EST: '+372',
  ETH: '+251',
  PHL: '+63',
  FIN: '+358',
  FJI: '+679',
  FRA: '+33',
  GAB: '+241',
  GMB: '+220',
  GEO: '+995',
  GHA: '+233',
  GIB: '+350',
  GRD: '+1 473',
  GRC: '+30',
  GRL: '+299',
  GLP: '+',
  GUM: '+1 671',
  GTM: '+502',
  GUF: '+',
  GGY: '+',
  GIN: '+224',
  GNQ: '+240',
  GNB: '+245',
  GUY: '+592',
  HTI: '+509',
  HND: '+504',
  HKG: '+852',
  HUN: '+36',
  IND: '+91',
  IDN: '+62',
  IRN: '+98',
  IRQ: '+964',
  IRL: '+353',
  BVT: '+',
  IMN: '+44',
  CXR: '+61',
  NFK: '+',
  ISL: '+354',
  BMU: '+1 441',
  CYM: '+1 345',
  CCK: '+61',
  COK: '+682',
  ALA: '+',
  FRO: '+298',
  SGS: '+',
  HMD: '+',
  MDV: '+960',
  FLK: '+500',
  MNP: '+1 670',
  MHL: '+692',
  PCN: '+870',
  SLB: '+677',
  TCA: '+1 649',
  UMI: '+',
  VG: '+1 284',
  VIR: '+1 340',
  ISR: '+972',
  ITA: '+39',
  JAM: '+1 876',
  JPN: '+81',
  JEY: '+',
  JOR: '+962',
  KAZ: '+7',
  KEN: '+254',
  KGZ: '+996',
  KIR: '+686',
  KWT: '+965',
  LBN: '+961',
  LAO: '+856',
  LSO: '+266',
  LVA: '+371',
  LBR: '+231',
  LBY: '+218',
  LIE: '+423',
  LTU: '+370',
  LUX: '+352',
  MEX: '+52',
  MCO: '+377',
  MAC: '+853',
  MKD: '+389',
  MDG: '+261',
  MYS: '+60',
  MWI: '+265',
  MLI: '+223',
  MLT: '+356',
  MAR: '+212',
  MTQ: '+',
  MUS: '+230',
  MRT: '+222',
  MYT: '+262',
  FSM: '+691',
  MDA: '+373',
  MNG: '+976',
  MNE: '+382',
  MSR: '+1 664',
  MOZ: '+258',
  NAM: '+264',
  NRU: '+674',
  NPL: '+977',
  NIC: '+505',
  NER: '+227',
  NGA: '+234',
  NIU: '+683',
  NOR: '+47',
  NCL: '+687',
  NZL: '+64',
  OMN: '+968',
  NLD: '+31',
  PAK: '+92',
  PLW: '+680',
  PSE: '+',
  PAN: '+507',
  PNG: '+675',
  PRY: '+595',
  PER: '+51',
  PYF: '+689',
  POL: '+48',
  PRT: '+351',
  PRI: '+1',
  QAT: '+974',
  GBR: '+44',
  CAF: '+236',
  CZE: '+420',
  DOM: '+1 809',
  REU: '+',
  RWA: '+250',
  ROU: '+40',
  RUS: '+7',
  ESH: '+',
  WSM: '+685',
  ASM: '+1 684',
  BLM: '+590',
  KNA: '+1 869',
  SMR: '+378',
  MAF: '+1 599',
  SPM: '+508',
  VCT: '+1 784',
  SHN: '+290',
  LCA: '+1 758',
  STP: '+239',
  SEN: '+221',
  SRB: '+381',
  SYC: '+248',
  SLE: '+232',
  SGP: '+65',
  SYR: '+963',
  SOM: '+252',
  LKA: '+94',
  ZAF: '+27',
  SDN: '+249',
  SWE: '+46',
  CHE: '+41',
  SUR: '+597',
  SJM: '+',
  SWZ: '+268',
  TJK: '+992',
  THA: '+66',
  TWN: '+886',
  TZA: '+255',
  IOT: '+',
  ATF: '+',
  TLS: '+670',
  TGO: '+228',
  TKL: '+690',
  TON: '+676',
  TTO: '+1 868',
  TUN: '+216',
  TKM: '+993',
  TUR: '+90',
  TUV: '+688',
  UKR: '+380',
  UGA: '+256',
  URY: '+598',
  UZB: '+998',
  VUT: '+678',
  VEN: '+58',
  VNM: '+84',
  WLF: '+681',
  YEM: '+967',
  DJI: '+253',
  ZMB: '+260',
  ZWE: '+263',
};
module.exports = { phoneByISO3, phonesByPrefix };
