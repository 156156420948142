import React, { Component } from 'react';
import { Button } from '../layouts/cssstyled';
import IconLoading from '../components/icons/IconLoading';
//import Timeout from '../components/Timeout';
class Modal extends Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.state = {
      clicked: false,
    };
  }

  /*
  discontinued, conflictive, the state clicked is managed on parent, and click buttons calll direct functions
  handleClick = (e, action, callback = null) => {
    console.log('do click');
    if (!this.state.clicked) { // TWIN CTR231 ctrl is not saving this
      const realtimeout = this.timeout;
      this.setState( {clicked: true} , async () => {
        const res = await action(callback);
      });
    }
  };
   */

  componentDidMount = () => {
    this.mounted = true;
    //this.timeout = this.timer();
  };

  componentWillUnmount = () => {
    this.mounted = false;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  };

  render() {
    const btnClose = (
      <button
        onClick={() => this.props.onClose()}
        type="button"
        aria-label="close"
        style={{ fontSize: '25px', color: 'steelblue' }}
      >
        X
      </button>
    );
    // close button for header with color
    const btnCloseInHeader = (
      <button
        onClick={() => this.props.onClose()}
        type="button"
        aria-label="close"
        style={{ fontSize: '25px', color: '#FFFFFF' }}
      >
        X
      </button>
    );
    if (!this.props.open) return null;
    const styleM = {};
    if (this.props.backgroundColor)
      styleM.backgroundColor = this.props.backgroundColor;
    if (this.props.scroll) {
      let footer = null;
      if (this.props.clicked) {
        footer = [];
        footer.push(<IconLoading key="i" size="35" />);
      } else {
        if (this.props.buttons) {
          footer = [];
          this.props.buttons.map((button, index) => {
            footer.push(
              <Button
                key={button.title}
                margin="1"
                onClick={(e) => {
                  button.action(button.callback);
                }}
              >
                {' '}
                {button.title}{' '}
              </Button>
            );
          });
        }
      }
      return (
        <div className="modal-background">
          <div
            className="modal3 open"
            style={{
              width:
                this.props.size === 'full' ? '100%' : this.props.size || '',
            }}
          >
            <div className="modal3_header">
              {this.props.header && (
                <header>
                  {this.props.header && <span>{this.props.header}</span>}
                  {this.props.close && btnCloseInHeader}
                </header>
              )}
            </div>
            <div className="modal3_content" style={styleM}>
              <div style={{ padding: '20px' }}>{this.props.children}</div>
            </div>
            {footer && (
              <div
                className="modal3_footer"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                {footer}
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="modal-background">
          <div
            role="dialog"
            className="modal-dialog"
            style={{ width: '680px' }}
          >
            {this.props.header && (
              <header>
                {this.props.header && <span>{this.props.header}</span>}
                {this.props.close && btnCloseInHeader}
              </header>
            )}
            {!this.props.header && (
              <div style={{ fontSize: '25px' }}>{btnClose}</div>
            )}
            <div className="modal-content">
              <div style={{ padding: '20px' }}>{this.props.children}</div>
            </div>
          </div>
        </div>
      );
    }
  }
}
//export default Timeout(Modal); //does not work well
export default Modal;
