import { delay } from 'redux-saga';
import { put, select, takeEvery } from 'redux-saga/effects';
import { showNotification, hideNotification } from '../actions/notifActions';

let nextNotificationId = 0;

/*

 CYCLE
    - showNotificationWithTimeout(action exposed in props) ===>  notifActions.js

         LayoutContainer.js;
         import { showNotificationWithTimeout } from '../../actions/notifActions'; // import action reducer, to be exposed in [*E]
         render()
          <Layout
            t={t}
            showNotificationWithTimeout={this.props.showNotificationWithTimeout} // send child props action reducer, exposed in [*E]

          const mapStateToProps = (state) => {
            return {
              themeName: state.app.themeName,
              toasts: state.toasts,
              notifications: state.notifications,
            };
          };
          // expose action reducer in props [*E]
          const App = connect(mapStateToProps, { showNotificationWithTimeout })(LayoutContainer);




       showNotificationWithTimeout = (text, isError)=> ({ type: 'SHOW_NOTIFICATION_WITH_TIMEOUT', text , isError });
          showNotificationWithTimeout ('there is erro',true) <=== Example call with two parameters  (text, isError)

    - sagaAction (action)   ===> this file
         action:  {
         type: "SHOW_NOTIFICATION_WITH_TIMEOUT",
         text: "Il y une erreur de connection sur Internet ou avec le serveur",
         isError: true
         }
    - showNotification(id, action.text, action.isError) ===>  notifActions.js
    - notifReducer.js

         action:
         {
         type: "SHOW_NOTIFICATION",
         id: 1,
         text: "Il y une erreur de connection sur Internet ou avec le serveur",
         isError: true,
         @@redux-saga/SAGA_ACTION: true
         }

         case 'SHOW_NOTIFICATION':
            return [...state, { id: action.id, text: action.text, isError: action.isError }];


 FUNCTION: sagaAction(action)
  is dispatched by action 'showNotificationWithTimeout' from notifActions.js
       showNotificationWithTimeout = (text, isError)=> ({ type: 'SHOW_NOTIFICATION_WITH_TIMEOUT', text , isError });
          this actions is exposed like props in component

 PARAMETERS:
 action:  {
   type: "SHOW_NOTIFICATION_WITH_TIMEOUT",
   text: "Il y une erreur de connection sur Internet ou avec le serveur",
   isError: true
 }



 */

const getState = (state) => state.notifications;

function* sagaAction(action) {
  nextNotificationId += 1;
  const id = nextNotificationId;
  const state = yield select(getState);

  // check error is not already present
  /*
  const msgFound= state.filter(item=> {
    const test1= item.text === action.text;
    return test1;
  }).length;
  if ( msgFound === 0) {
  */

  /*
    if delay is sent (not null) it's in seconds, then multiplicate x 10000
   */

  if (!state.some((item) => item.text === action.text)) {
    yield put(showNotification(id, action.text, action.isError));
    if (!action.isError || action.isError === 3) {
      // 0 or 3 Eg, saved || deleted msg
      yield delay(action.delay ? action.delay * 1000 : 3000);
      yield put(hideNotification(id));
    } else if (action.isError === 2) {
      yield delay(action.delay ? action.delay * 1000 : 5000);
      yield put(hideNotification(id));
    } else {
      // 1 = error real system, dont hide %% at least there is page change
    }
  }
}

function* notificationSaga() {
  yield takeEvery('SHOW_NOTIFICATION_WITH_TIMEOUT', sagaAction);
}

function* rootSaga() {
  yield [notificationSaga()];
}

export default rootSaga;
