/*

 action:
  {
    type: "SHOW_NOTIFICATION",
    id: 1,
    text: "Il y une erreur de connection sur Internet ou avec le serveur",
    isError: true,
    @@redux-saga/SAGA_ACTION: true
  }
 */
const notifications = (state = [], action) => {
  // initialState ist just empty array
  switch (action.type) {
    case 'SHOW_NOTIFICATION':
      return [
        ...state,
        { id: action.id, text: action.text, isError: action.isError },
      ];
    case 'HIDE_NOTIFICATION':
      return state.filter((notification) => notification.id !== action.id);
    default:
      return state;
  }
};

export default notifications;
