export const normalizes = {};

normalizes.Date = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return `${onlyNums}`;
  }

  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2)}`;
  }
  return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 4)}-${onlyNums.slice(
    4,
    8
  )}`;
};

normalizes.LowerCase = (value) => {
  if (!value) {
    return value;
  }

  return value.toLowerCase();
};

normalizes.LowerCaseTrim = (value) => {
  if (!value) {
    return value;
  }
  return value.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '');
};

normalizes.UpperCase = (value) => {
  if (!value) {
    return value;
  }

  return value.toUpperCase();
};

// Don't forced after first character to lowercase
normalizes.StartCase = (value) => {
  if (!value) {
    return value;
  }

  return value.substring(0, 1).toUpperCase() + value.substr(1);
};

// Forced after first character to lowercase
normalizes.StartCaseForced = (value) => {
  if (!value) {
    return value;
  }
  return value.substring(0, 1).toUpperCase() + value.substr(1).toLowerCase();
};

normalizes.IntPositive = (value) => {
  if (!value) {
    return value;
  }

  return value.replace(/[^\d]/g, '');
};

normalizes.Phone = (value) => {
  if (!value) {
    return value;
  }
  // allow chars: space ( ) numbers + .
  return value.replace(/[^+\(\) .\d]/g, '');
};

normalizes.Price = (value) => {
  if (!value) {
    return value;
  }
  value = value.replace(/[^0-9.]/g, ''); // Remove all chars except numbers and .

  // Create an array with sections split by .
  const sections = value.split('.');

  // Remove any leading 0s apart from single 0
  if (sections[0] !== '0' && sections[0] !== '00') {
    sections[0] = sections[0].replace(/^0+/, '');
  } else {
    sections[0] = '0';
  }

  // If numbers exist after first .
  if (sections[1]) {
    // Join first two sections and truncate end section to length 2
    return sections[0] + '.' + sections[1].slice(0, 2);
    // If original value had a decimal place at the end, add it back
  } else if (value.indexOf('.') !== -1) {
    return sections[0] + '.';
    // Otherwise, return only section
  } else {
    return sections[0];
  }
};

normalizes.PriceNegative = (value) => {
  if (!value) {
    return value;
  }
  value = value.replace(/[^0-9.\-]/g, ''); // Remove all chars except numbers and .

  // Create an array with sections split by .
  const sectionNegative = value.split('-');
  if (sectionNegative.length > 2) {
    const lIndex = value.lastIndexOf('-');
    value = value.substring(0, lIndex) + value.substr(lIndex + 1);
  } else if (sectionNegative.length > 1) {
    value = '-' + sectionNegative[1]; // ignore all chars on the right of -
  }
  const sections = value.split('.');

  // Remove any leading 0s apart from single 0
  if (sections[0] !== '0' && sections[0] !== '00') {
    sections[0] = sections[0].replace(/^0+/, '');
  } else {
    sections[0] = '0';
  }

  // If numbers exist after first .
  if (sections[1]) {
    // Join first two sections and truncate end section to length 2
    return sections[0] + '.' + sections[1].slice(0, 2);
    // If original value had a decimal place at the end, add it back
  } else if (value.indexOf('.') !== -1) {
    return sections[0] + '.';
    // Otherwise, return only section
  } else {
    return sections[0];
  }
};
