//const appSubmitStart = () => ({ submitting: true });
const appSubmitStart = () => {
  return {
    type: 'SUBMIT_START',
    payload: {
      submitting: true,
    },
  };
};

const appSubmitStop = () => {
  //console.log('stop submit action');
  return {
    type: 'SUBMIT_STOP',
    payload: {
      submitting: false,
    },
  };
};

const appSettingsLoad = (appSettings) => {
  //console.log('stop submit action');
  return {
    type: 'SETTINGS_LOAD',
    payload: {
      appSettings,
    },
  };
};

const appSetOrg = (org) => {
  //console.log('stop submit action');
  return {
    type: 'SETORG',
    payload: {
      org: org,
    },
  };
};

export { appSubmitStart, appSubmitStop, appSettingsLoad, appSetOrg };
