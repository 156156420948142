import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import format from 'date-fns/format';
import addMonths from 'date-fns/addMonths';
import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';

import datefnsLocale from 'date-fns/locale/fr';
import { Link } from 'react-router-dom';
import { appSubmitStart, appSubmitStop } from '../actions/appActions';
import ShowIcon from '../components/icons/ShowIcon';
import IconLoading from '../components/icons/IconLoading';
import { Tables } from '../defTables';
import FormField from './FormField';
import { getOptions, resolveLink, cleanFilters } from '../utils/helper';
import { normalizes } from '../utils/normalizes';
import { aValidator } from '../utils/validators';
import {
  getInputValue,
  getObjFromListById,
  getTheme,
  resolvePathObj,
  startCase,
  intersection,
} from '../utils/commonutils';
import themes from '../layouts/themes';

//import { withCrudTool } from './hocCrudToolBar';

// to implement model, button reactive to submitting, errors, etc
// https://github.com/DeedMob/redux-form-react-submitbutton/blob/master/src/index.js

//let formName = 'listFilter';

export class CrudToolBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
      /* state for filterCode */
      crudAction: props.crudAction,
      //crudTable: tableCrud,
      id: props.id ? props.id : '',
      disabledFields: [],
      hiddenFields: [],
    };

    setTimeout(
      function () {
        if (this.props.executeCode)
          this.props.executeCode('onChangeInput', {
            action: 'initForm',
            props: this.props,
            formProps: this.props,
            formState: this.state,
          });
      }.bind(this),
      1000
    );
  }

  handleClick = (e) => {
    if (this.props.myState.app.submitting) {
      e.preventDefault();
      ////console.error('submit twice');
      return;
    }
    if (!this.state.clicked) {
      // TWIN CTR231 ctrl is not saving this
      this.setState({ clicked: true }, async () => {
        this.props.appSubmitStart();
        await this.props.handleSubmit();
        this.props.appSubmitStop();
        this.setState({ clicked: false });
      });
    }
  };

  render() {
    let log = false;
    const theme = getTheme();
    //console.log(this.props);
    const props = this.props;
    const t = this.props.t;
    let table = Tables[props.tableCrud];
    // className="formSection" style = { { backgroundColor: '#73b4e60f'} }
    let filters = [];
    let containersFilters = [];
    let lnRealFilterQty = 0; // does not include orders, that are added in same array filters
    let orders = {};
    // always must be one unique array, does not support more one, have no sense
    let templates = this.props.templates || [];
    //if (props.action === 'list' &&, // have no sens this action, all componenets that
    //                                   include <CrudFilterOrderBar> are list
    filters.push(
      <Field
        key="_timer"
        name="_timer"
        inputName="_timer"
        type="hidden"
        typeInput="hidden"
        translateDisabledSource={true}
        t={props.t}
        component={FormField}
        formProps={this.props}
        nameForm={props.nameFilterForm}
      />
    );
    if (table.listFilters || table.orders) {
      let listFilters = table.listFilters
        ? Object.assign({}, table.listFilters.fields)
        : {};
      if (
        table.orders &&
        !(table.listFilters && table.listFilters.fields._orders)
      ) {
        // mix in a one object collection filters (if exist) and order (if exist)
        // but only if is not is written _order in fixed position between listFilters fields
        listFilters._orders = {}; //special key reserved for order
      }
      //console.log('filters',table.listFilters.fields);
      let blockIsMultiField = false;
      Object.keys(listFilters).map((fieldKey, keyIndex) => {
        let add = true;
        let fieldFilter = listFilters[fieldKey]; // %% read fields like preload
        if (fieldFilter.templates) {
          if (
            !intersection(
              fieldFilter.templates,
              templates,
              props.tableCrud,
              fieldKey
            )
          ) {
            add = false;
          }
        }
        if (
          fieldFilter.module &&
          !resolvePathObj(
            this.props,
            'myState.app.appSettings.modules.' + fieldFilter.module,
            true
          )
        ) {
          add = false;
        }
        if (fieldFilter.invisible) {
          add = false;
        }
        //console.log('fieldKey, fieldFilter.templates, add , ', fieldKey, fieldFilter.templates, add);
        if (add) {
          if (fieldKey === '_orders') {
            const listOptions = [];
            Object.keys(table.orders.fields).map((fieldKey, keyIndex) => {
              let add = true;
              let field = table.orders.fields[fieldKey]; // %% read fields like preload
              if (field.templates) {
                if (
                  !intersection(
                    field.templates,
                    templates,
                    props.tableCrud,
                    fieldKey
                  )
                ) {
                  add = false;
                }
              }
              if (
                field.module &&
                !resolvePathObj(
                  this.props,
                  'myState.app.appSettings.modules.' + field.module,
                  true
                )
              ) {
                add = false;
              }

              let fieldSource = table.fields[fieldKey];
              let name = t(field.label ? field.label : 'form.' + fieldKey);
              if (field.labelorder && field.order)
                name += ' ' + t('form.' + field.order);
              if (add) listOptions.push({ id: fieldKey, name });
            });

            /* Order Props */
            let inputProps = {};
            inputProps.type = 'selectBox';
            inputProps.typeInput = 'selectBox';
            inputProps.isFilter = true;
            inputProps.placeHolderShow = true;
            inputProps.pathInTables = props.tableCrud + '.orders'; // definition in tables is not _orders
            //console.log('table.orders',table.orders);
            if (table.orders.defaultValue)
              inputProps.defaultValue = table.orders.defaultValue;

            /* Order Input */
            filters.push(
              <Field
                key="_orders"
                name="_orders"
                inputName="_orders"
                translateDisabledSource={true}
                styleLabel={{ color: themes[theme].PrimaryDark }}
                listOptions={listOptions}
                t={props.t}
                component={FormField}
                formProps={this.props}
                {...inputProps}
                nameForm={props.nameFilterForm}
              />
            );

            // don't must show internal filter that is a parent, because parent is in the url, not able to include in filter
          } else if (
            fieldFilter.target.includes('maincrud') &&
            !(
              table.parentRelationField &&
              table.parentRelationField === fieldKey
            ) &&
            fieldKey !== '_orders'
          ) {
            // just filters setted for maincrud

            let multiFieldinputProps = {};
            let styleNormalInput = {};
            /*
            Multifield has never noMainContainer, will take all the line
             */
            if (fieldFilter.multiField) {
              blockIsMultiField = true;
              styleNormalInput.fontSize = '12px';
              styleNormalInput.minWidth = '120px';
              styleNormalInput.width = '160px';
              const multiFields = [];

              if (!fieldFilter.uniqueField) {
                for (const [fieldKey, field] of Object.entries(
                  fieldFilter.multiField.fields
                )) {
                  let add = true;
                  if (field.templates) {
                    if (
                      !intersection(
                        field.templates,
                        templates,
                        props.tableCrud,
                        fieldKey
                      )
                    ) {
                      add = false;
                    }
                  }
                  if (
                    field.module &&
                    !resolvePathObj(
                      this.props,
                      'myState.app.appSettings.modules.' + field.module,
                      true
                    )
                  ) {
                    add = false;
                  }
                  if (add) {
                    multiFields.push({ id: fieldKey, name: field.label });
                  }
                }
              }
              if (templates[0] && 'default' + templates[0] + 'disabled')
                multiFieldinputProps.disabled = true;

              /* Multifield Props */

              multiFieldinputProps.type = 'selectBox';
              multiFieldinputProps.typeInput = 'selectBox';
              multiFieldinputProps.isFilter = true;
              multiFieldinputProps.noLabel = true;
              // important all elements in line must to have this, to get same marginTop
              multiFieldinputProps.noMainContainer = true;
              // if one element is inLine on the line, all the elements must have .inLine = true
              multiFieldinputProps.inLine = true;
              // anyway multiField must have a placeHolder on the selectBox

              if (fieldFilter.placeHolder) {
                multiFieldinputProps.placeHolder = fieldFilter.placeHolder;
                multiFieldinputProps.placeHolderShow = true;
              }

              /* Multifield Input Eg; select type date*/
              const fieldKeyMulti = fieldKey + 'Field'; // TWIN MUL892 generator
              if (fieldFilter.uniqueField) {
                filters.push(
                  <Field
                    key="_multidateField"
                    name="_multidateField"
                    inputName="_multidateField"
                    type="hidden"
                    typeInput="hidden"
                    component={FormField}
                    formProps={this.props}
                    nameForm={props.nameFilterForm}
                  />
                );
              } else {
                filters.push(
                  <Field
                    key={fieldKeyMulti}
                    name={fieldKeyMulti}
                    inputName={fieldKeyMulti}
                    styleLabel={{ color: themes[theme].PrimaryDark }}
                    listOptions={multiFields}
                    t={props.t}
                    component={FormField}
                    formProps={this.props}
                    {...multiFieldinputProps}
                    nameForm={props.nameFilterForm}
                  />
                );
              }
            }

            const labelInDiv =
              multiFieldinputProps && multiFieldinputProps.noLabel
                ? false
                : true;

            let inputProps = {};
            inputProps.type = fieldFilter.type;
            inputProps.typeInput = fieldFilter.typeInput;
            inputProps.isFilter = true;
            if (!fieldFilter.showLabel) inputProps.noLabel = true;
            inputProps.sublabeldown = true;
            inputProps.placeHolderShow = true;
            if (fieldFilter.normalize)
              inputProps.normalize = normalizes[fieldFilter.normalize];
            if (fieldFilter.sublabel)
              inputProps.sublabel = fieldFilter.sublabel;
            if (fieldFilter.listSource) {
              inputProps.listSource = fieldFilter.listSource;
            }
            if (fieldFilter.defaultValue) {
              inputProps.defaultValue = fieldFilter.defaultValue;
            }
            let listOptions = [];

            if (fieldFilter.listOptions)
              listOptions = [...fieldFilter.listOptions];
            let rangeDate = undefined;

            if (fieldFilter.listOptions) {
              // options will change type or assign type
              rangeDate = getObjFromListById(
                fieldFilter.listOptions,
                { name: 'form.rangedate' },
                true
              );
              //inputProps.radio = fieldFilter.radio;
              //fieldFilter.typeInput = 'radio';
            }

            if (fieldFilter.typeInput) {
              // if in filter is defined typeInput will asign, important
              inputProps.typeInput = fieldFilter.typeInput;
              inputProps.type = fieldFilter.typeInput;
            }

            // select autocomplete need , set value, obligatory when is set by initialValues
            // and the form does not exist, but after initiliazed don't needed, but it's ok anyway let it like this
            if (
              fieldFilter.fieldSource &&
              fieldFilter.fieldSource.typeInput === 'selectAutocomplete'
            ) {
              //let realFieldKey = fieldFilter.subfield ? fieldFilter.subfield : realFieldKey;
              const valueFromState = getInputValue(this.props, fieldKey, {
                nameForm: props.nameFilterForm,
              });
              //console.log('valueFromState for '+fieldKey+':', valueFromState);
              if (valueFromState) {
                inputProps.value = valueFromState;
                //console.log('inputProps.value ', inputProps.value);
              }
            }
            lnRealFilterQty++;

            /* props for: 1) date ranges inputs, 2) normal input */
            if (typeof rangeDate !== 'undefined') {
              inputProps.inLine = true;
              inputProps.noMainContainer = true;
              inputProps.noContainerInLine = true;
              inputProps.noLabel = true;
              //inputProps.width = '70px' ; has no effets %%
            }

            /* normal input */
            // consider labeltype only if container (multiField by now)  had label
            if (
              fieldFilter.typeShowInput &&
              fieldFilter.typeShowInput === 'Date'
            ) {
              let newOptions = [];
              listOptions.map((myoption) => {
                const option = { ...myoption }; // need don't store direct, otherwise cumulates
                if (option.id === '60') {
                  option.name =
                    t('form.month') +
                    ': ' +
                    startCase(
                      format(Date.now(), 'MMMM YYYY', { locale: datefnsLocale })
                    );
                } else if (option.id === '70') {
                  option.name =
                    t('form.month') +
                    ': ' +
                    startCase(
                      format(addMonths(Date.now(), 1), 'MMMM YYYY', {
                        locale: datefnsLocale,
                      })
                    );
                } else if (option.id === '50') {
                  option.name =
                    t('form.month') +
                    ': ' +
                    startCase(
                      format(addMonths(Date.now(), -1), 'MMMM YYYY', {
                        locale: datefnsLocale,
                      })
                    );
                } else if (option.id === '100') {
                  option.name = format(addDays(Date.now(), -45), 'MMMM YYYY', {
                    locale: datefnsLocale,
                  });
                } else if (option.id === '200') {
                  option.name = format(addYears(Date.now(), 0), 'YYYY', {
                    locale: datefnsLocale,
                  });
                } else if (option.id === '205') {
                  option.name = format(addYears(Date.now(), -1), 'YYYY', {
                    locale: datefnsLocale,
                  });
                } else if (option.id === '210') {
                  option.name = format(addYears(Date.now(), 1), 'YYYY', {
                    locale: datefnsLocale,
                  });
                } else {
                  option.name = t(option.name);
                }
                let add = true;

                if (option.templates) {
                  if (
                    !intersection(option.templates, templates, props.tableCrud)
                  ) {
                    add = false;
                  }
                }

                if (add) newOptions.push(option);
              });
              inputProps.listOptions = newOptions;
            }
            filters.push(
              <Field
                key={fieldKey}
                name={fieldKey}
                inputName={fieldKey}
                t={props.t}
                styleInput={styleNormalInput}
                component={FormField}
                pathInTables={
                  props.tableCrud + '.listFilters.fields.' + fieldKey
                }
                formProps={this.props}
                {...inputProps}
                labelInDiv={labelInDiv}
                translateDisabledSource={
                  (fieldFilter.fieldSource &&
                    fieldFilter.fieldSource.translateDisabledSource) ||
                  fieldFilter.translateDisabledSource
                    ? true
                    : ''
                }
                nameForm={props.nameFilterForm}
                executeCode={this.props.executeCode}
                onChange={(event, newValue, previousValue) => {
                  //if (this.props.executeCode)
                  this.props.executeCode('onChangeInput', {
                    inputFullName: fieldKey,
                    formProps: this.props,
                    formState: this.state,
                    event,
                    newValue,
                    previousValue,
                  });
                }}
              />
            );

            if (typeof rangeDate !== 'undefined') {
              // it's date range, show ranges inputs
              const valueOptionFromMulti = getInputValue(this.props, fieldKey, {
                nameForm: props.nameFilterForm,
              });
              //  becaonly show range if option selected is range, use simple compare == because string against int
              if (valueOptionFromMulti == rangeDate.id) {
                const styleRangeInput = { fontSize: '13px', marginTop: '0px' }; // don't insert color, because disabled
                if (labelInDiv) styleRangeInput.marginTop = '17px';
                for (let r = 1; r <= 2; r += 1) {
                  let realFieldKey = fieldKey + r;
                  // dont send {...inputProps} // radiobutto is different from textbox for rangedate
                  // reset marginTop: '17px' so is aligned in all inline object, because there is label, otherwise 0px
                  /* inputs for date range */
                  filters.push(
                    <Field
                      key={realFieldKey}
                      name={realFieldKey}
                      inputName={realFieldKey}
                      t={props.t}
                      width="152px"
                      noErrors={true}
                      styleInput={styleRangeInput}
                      component={FormField}
                      pathInTables={
                        props.tableCrud + '.listFilters.fields.' + realFieldKey
                      }
                      formProps={this.props}
                      listOptions={listOptions}
                      type="date"
                      typeInput="date"
                      noLabel={true}
                      inLine={true}
                      noMainContainer={true}
                      noContainerInLine={true}
                      translateDisabledSource={
                        fieldFilter.fieldSource &&
                        fieldFilter.fieldSource.translateDisabledSource
                          ? fieldFilter.fieldSource.translateDisabledSource
                          : ''
                      }
                      nameForm={props.nameFilterForm}
                      onHidden={true}
                      executeCode={this.props.executeCode}
                      formState={this.state}
                      disabled={
                        //this.executeCode( 'onDisabled', { inputFullNameWithLine: realFieldKey, formProps: this.props, formState: this.state })
                        this.state.disabledFields &&
                        this.state.disabledFields[realFieldKey]
                      }
                      onChange={(event, newValue, previousValue) => {
                        //if (this.props.executeCode)
                        this.props.executeCode('onChangeInput', {
                          inputFullName: realFieldKey,
                          formProps: this.props,
                          formState: this.state,
                          event,
                          newValue,
                          previousValue,
                        });
                      }}
                    />
                  );
                }
              }
            }
          }
        }
        //console.log(keyIndex ,fieldFilter);
        let nextKey = Object.keys(listFilters)[keyIndex + 1];
        if (
          keyIndex === Object.keys(listFilters).length - 1 || // is last item
          (typeof nextKey !== 'undefined' &&
            listFilters[nextKey].formNewSection)
        ) {
          //console.log('add filters', nextKey,filters);
          let styleContainerBlock = { backgroundColor: '#73b4e60f' };
          if (blockIsMultiField && !listFilters._multidate.uniqueField) {
            styleContainerBlock.border = 'dashed 1px ' + themes[theme].Primary;
            styleContainerBlock.margin = '5px 0px 5px 0px';
            styleContainerBlock.padding = '10px 3px 10px 7px';
          }
          containersFilters.push(
            <div
              key={containersFilters.length}
              className="formFilterSection"
              style={styleContainerBlock}
            >
              {filters}
            </div>
          );
          filters = [];
        }
      });
    }

    return (
      <div style={{ marginBottom: '7px' }}>
        <form>
          {lnRealFilterQty > 0 && (
            <div>
              {this.props.t('form.filters')}:{' '}
              <a
                title={this.props.t('form.cleanfilter')}
                onClick={() =>
                  cleanFilters(
                    this.props.tableCrud,
                    this.props.dispatch,
                    change
                  )
                }
              >
                <ShowIcon color="#999999" icon="cleanfilters" />
              </a>
            </div>
          )}
          {
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {containersFilters}
            </div>
          }
        </form>
      </div>
    );
  }
}

const ComponentReduxedForm = reduxForm({
  // this name can't be hardcode, becase same filters is used for more one form
  //  so the name will be assigned in mapStateToProps (see below)
  //    so don't use here:
  //        form: formName
  //  don't use enableReinitialize because every time initialValues changes the form lost his value
  //      and then is produced with manual assign query params in url ?tour_id, next time that visit
  //        the page , value is lost
  //    enableReinitialize: true,
  //    keepDirtyOnReinitialize: true,
  /*
  important these two properties to keep the value of filters permanent
   */
  destroyOnUnmount: false,

  // TWIN FR141 very important , the values that are loading and changing  after form is created
  // so need reload the last variables in form
})(CrudToolBar);

//const withComponentReduxedForm  = withCrudTool(ComponentReduxedForm,formName );
const withComponentReduxedForm = ComponentReduxedForm;

function mapStateToProps(state, ownProps) {
  let initialValues = {};
  const formFilterName = ownProps.nameFilterForm;
  let stateToProps = {
    myState: state,
  };
  stateToProps.form = formFilterName; // dynamic namen, because
  //warning, have no use checj state, we don't use don't use enableReinitialize, same, have no sense, very confuse this method
  //   if (state && state.form[formFilterName] ) { // form state exist
  // have no sens below if, all componentes include crudFilterOrderBar must filter
  //if (ownProps.action === 'list' ) { // logic just for list, when there is declared a filter form
  // twin DEFINI318, need double code to work, 1) filter for query, 2) initialvalue for form CrudFilterOrderBar
  // %% hardcode for inactive field, but on the future, must to look for filters fields and look .defaultValue
  initialValues.inactive = 'false';
  if (ownProps.tableCrud && Tables[ownProps.tableCrud].orders) {
    if (
      ownProps.templates &&
      Tables[ownProps.tableCrud].orders['default' + ownProps.templates[0]]
    ) {
      // when is templates active, first template is the main template, check default value
      initialValues._orders =
        Tables[ownProps.tableCrud].orders['default' + ownProps.templates[0]];
    } else if (Tables[ownProps.tableCrud].orders.defaultValue) {
      initialValues._orders = Tables[ownProps.tableCrud].orders.defaultValue;
    }
  }
  if (
    Tables[ownProps.tableCrud].listFilters &&
    Tables[ownProps.tableCrud].listFilters.fields._multidate &&
    Tables[ownProps.tableCrud].listFilters.fields._multidate.uniqueField
  ) {
    // getn name from unique field
    initialValues._multidateField = Object.keys(
      Tables[ownProps.tableCrud].listFilters.fields._multidate.multiField.fields
    )[0];
  }
  if (
    ownProps.containerPropsForm.history &&
    Tables[ownProps.tableCrud].listFilters
  ) {
    // this is executed one time for the creation form so will not interfere with manual changes in list box
    const search = ownProps.containerPropsForm.history.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    //console.log('params',params);
    Object.keys(Tables[ownProps.tableCrud].listFilters.fields).map(
      (fieldKey) => {
        //console.log('fieldKey',fieldKey);
        let fieldFilter =
          Tables[ownProps.tableCrud].listFilters.fields[fieldKey];

        // in multifield , main field (selectbox) is +'Field'
        const fieldKeyDefault =
          fieldKey + (fieldFilter.multiField ? 'Field' : '');
        const defaultValueTemplate = ownProps.templates
          ? fieldFilter['default' + ownProps.templates[0]]
          : null;
        if (ownProps.templates && defaultValueTemplate) {
          // when is templates active, first template is the main template, check default value
          initialValues[fieldKeyDefault] = defaultValueTemplate;
          if (fieldFilter['default' + ownProps.templates[0] + '_option']) {
            // case default option
            initialValues[fieldKey] =
              fieldFilter['default' + ownProps.templates[0] + '_option'];
          }
        } else if (fieldFilter.defaultValue) {
          initialValues[fieldKeyDefault] = fieldFilter.defaultValue;
        }
        if (params.get(fieldKey)) {
          // default value accord parameter
          if (
            fieldFilter.fieldSource &&
            fieldFilter.fieldSource.typeInput === 'selectAutocomplete' &&
            fieldFilter.fieldSource.saveonly
          ) {
            // is async
            initialValues[fieldKey] = {
              id: params.get(fieldKey),
              name: params.get(fieldKey.replace('_id', '_name')),
            };
          } else {
            initialValues[fieldKey] = params.get(fieldKey);
          }
        }
      }
    );
    if (params.get('_orders')) initialValues['_orders'] = params.get('_orders');
  }
  stateToProps.initialValues = initialValues;
  //console.log('initialValues',initialValues);
  //}
  return stateToProps;
}

function mapDispatchToProps(dispatch) {
  //return bindActionCreators(reduxFormActions.change, dispatch);
}

const ComponentWithDataAndState = connect(
  mapStateToProps,
  {
    appSubmitStart,
    appSubmitStop,
  }
  //mapDispatchToProps,
)(withComponentReduxedForm);

export default ComponentWithDataAndState;
