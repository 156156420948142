/*
validatos return false or '' if validation is ok
normally if value is empty, undefined or null return '' or his same value
if return string then will print accor to translations.js::validators section
but if the string contains '::' means is a translation customized , return that translation
 */
const { isLength, isEmail, isInt, minV } = require('validator');
const {
  parse: parseDate,
  differenceInCalendarDays: differenceInDays,
} = require('date-fns');
//import XRegExp from 'xregexp';
//const { toNumber } = require('./parsers');
const { dateClean } = require('../utils/commonutils');
const { phonesByPrefix } = require('../utils/phonesutils');
const toNumber = (value) => {
  return parseInt(value || 0, 10);
};

let setDateFormat = localStorage.getItem('setDateFormat');

// dateString is type yyyy/mm/dd
const date = (dateString) => {
  if (!dateString || dateString === undefined) {
    return false; // empty is valid date
  }
  const p = dateString.split(/\D/g); // \D = Do a global search for non-digit characters:
  let date;
  if (setDateFormat) {
    // console.log('aaa', dateString);
    if (dateString.length != 10) return true; // datea invalid
    date = new Date(p[2], p[1] - 1, p[0]); // twin DAT910
    // console.log('bb', date);
  } else {
    date = new Date(p[0], p[1] - 1, p[2]); // twin DAT910
  }
  if (date == 'Invalid Date') {
    return true;
  }
  return !(date && date.getMonth() + 1 == p[1]); // only support month on the middle
};

const required = (value) => {
  if (value) {
    if (typeof value === 'string' && value.trim().length === 0) {
      // can be object for subfield table {id, name}
      ////console.log('value empty, then throw');
      return true;
    }
    return false;
  } else {
    ////console.log('required not value, then throw');
    return true;
  }
  return false;
};
const maxLength = (value, max) => {
  if (value) {
    max = toNumber(max);
    return value && value.length > max;
  } else {
    return false; // for blank value , no validation
  }
};

const minLength = (value, min) => {
  min = toNumber(min);
  if (value) {
    return value.length < min;
  } else {
    return false; // for blank value , no validation
  }
};

const length = (value, min, max) => {
  min = toNumber(min);
  max = toNumber(max);
  let fielderrors = '';
  if (min > 0 && max > 0) {
    if (!isLength(value, { min, max })) {
      return true;
    }
  } else if (min > 0) {
    return minLength(min);
  } else if (max > 0) {
    return maxLength(min);
  }
  return false;
};

const number = (value) => value && isNaN(Number(value));

const minValue = (value, min) => {
  value = toNumber(value);
  if (value >= min) {
    return false;
  } else {
    return true; // for blank, validation reject, need minimum value
  }
};

const email = (value) => {
  return value && !isEmail(value);
};

const pattern = (value, mypattern) => {
  return value && !mypattern.test(value);
};

const alphai18n = (value) => {
  /*
  old with xreg lirary, but it's a weight library
  value = value.replace(/-|\s/g,""); // delete dashs and spaces which are not valid for alpha
  const unicodeWord = XRegExp('^\\p{L}+$');
  return (value && !unicodeWord.test (value) ? 'validator.alpha' : '');*/

  ////console.log('alphaUS');
  // now just verify is there are number, to see it's really international alpha %% possible to add forbbidn chars
  return value && /[0-9]/i.test(value) ? 'validator.alphaUS' : '';
};

const alphaUS = (value) => {
  return value && /[^a-zA-Z]/i.test(value) ? 'validator.alphaUS' : '';
};

const password = (value) => {
  return value && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(value)
    ? 'validator.password'
    : '';
};

const Int = (value) => {
  if (!value) {
    return value;
  }
  if (typeof value !== 'string') value = value.toString();
  return !isInt(value, { min: 1 });
};

const IntRange = (value, min, max) => {
  if (!value) {
    return value;
  }
  if (typeof value !== 'string') value = value.toString();
  min = toNumber(min);
  max = toNumber(max);
  //console.log('min ,  max', min, max);
  return !isInt(value, { min, max });
};

const alphaUSNumeric = (value) =>
  value && /[^a-zA-Z0-9]/i.test(value) ? 'validator.alphaUSNmeric' : '';
const phoneNumber = (value) =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : '';

const daysLimitFuture = (value, max) => {
  if (!value) {
    // if is empty return empty
    return value;
  }
  if (date(value)) return ''; // only if date is valid continue
  max = toNumber(max);
  //warning  special format lowercase for the right year and day, don't use uppercase
  const pureDate = parseDate(dateClean(value), 'yyyyMMdd', new Date());
  // too future?
  let difference = differenceInDays(pureDate, new Date());
  if (difference > max) {
    return 'validator.daysLimitFuture::' + max + '::' + difference;
  } else {
    return '';
  }
};

const daysLimitOld = (value, min) => {
  if (!value) {
    // if is empty return empty
    return value;
  }
  if (date(value)) return ''; // only if date is valid continue  min = toNumber(min);
  const cleanDate = dateClean(value);
  //warning  special format lowercase for the right year and day, don't use uppercase
  const pureDate = parseDate(cleanDate, 'yyyyMMdd', new Date());
  // too old?
  let difference = differenceInDays(new Date(), pureDate);
  if (difference > min) {
    return 'validator.daysLimitOld::' + min + '::' + difference;
  } else {
    return '';
  }
};

const validUUID = (value) => {
  if (!value) {
    // if is empty return empty
    return value;
  }
  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  if (!regex.test(value)) {
    return 'validator.uuidInvalid';
  } else {
    return '';
  }
};

const valPhone = (phone, index, props, par2, par3, options = {}) => {
  /*
  index >= come from fieldarray
  index = null, come from field direct
   */
  if (!phone) {
    // if is empty return empty
    return phone;
  }
  let realphone = phone.replace('(0)', ' ');
  realphone = realphone.replace(/[^+ \d]+/g, '').trim(); // remove all except + and numbers, and espaces
  let phoneError = '';

  let phonePrefix = '+33';
  if (realphone.substr(0, 1) === '0') {
    realphone = phonePrefix + ' ' + realphone.substr(1);
  } else {
    // need to send :: , to be not overwritten in commonValidator
    if (realphone.substr(0, 1) !== '+') {
      phoneError = 'phonePlus';
    }
  }

  // let first space, and remove rest
  const phoneToSplit = realphone.split('');
  realphone = '';
  let spaceOccurence = 0;
  let prefix = realphone.substr(0, 3);
  phoneToSplit.map((letter) => {
    if (letter === ' ') {
      spaceOccurence++;
      if (spaceOccurence === 1) prefix = realphone;
    }
    if (!(spaceOccurence > 1 && letter === ' ')) realphone += letter;
  });
  if (spaceOccurence === 0) {
    phoneError = 'phonePrefixSpace';
  }

  /*
  validation country local prefix, mobile , fix and type
   */
  if (!phoneError) {
    if (prefix === '+33') {
      if (realphone.length !== 13) {
        // include +33 and 9 numbers= 12
        phoneError = 'phone33';
      } else if (
        props &&
        props.values &&
        props.values.phones &&
        props.values.phones[index]
      ) {
        // before enter make sure is not null
        const itemPhone = props.values.phones[index];
        if (!itemPhone.type || !itemPhone.type.id) {
          // type empty
          phoneError = 'phoneType';
        } else {
          if (
            realphone.substr(4, 1) === '6' ||
            realphone.substr(4, 1) === '7'
          ) {
            // it's using 6 or 7 but not with fix number
            if (itemPhone.type.id !== 1) {
              phoneError = 'phoneType';
            }
          } else {
            if (itemPhone.type.id === 1) {
              // is type mobile, but is not 6 or 7
              phoneError = 'phoneType';
            }
          }
        }
      }
    }
  }

  let countryISO3 = phonesByPrefix[prefix]; // give the country ISO3
  if (!countryISO3) {
    if (!phoneError) phoneError = 'phonePrefix::' + prefix;
    countryISO3 = ''; // let resolve always this varaible same if there is error
  }

  const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

  if (!phoneError) {
    if (!regex.test(realphone)) {
      phoneError = 'phoneWrong';
    }
  }

  if (options.getCleaned) {
    // for crudCode simply return  formated number, same with error
    return { realphone, phoneError, countryISO3 };
  }
  if (phoneError) {
    // need to send :: , to be not overwritten in commonValidator
    return 'validator.' + phoneError + '::';
  } else {
    //if (options.getCleaned) {
    //  return realphone;
    //} else {
    return '';
    //}
  }
};

const daysLimitOldToday = (value) => {
  if (!value) {
    // if is empty return empty
    return value;
  }
  if (date(value)) return ''; // only if date is valid continue  min = toNumber(min);
  //warning  special format lowercase for the right year and day, don't use uppercase
  const pureDate = parseDate(dateClean(value), 'yyyyMMdd', new Date());
  let difference = differenceInDays(pureDate, new Date());
  if (difference > 0) {
    return 'validator.daysLimitOldToday::' + difference;
  } else {
    return '';
  }
};
//website
//"https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)")

const gps = (coordinates) => {
  if (!coordinates) {
    // if is empty return empty
    return coordinates;
  }

  var args = coordinates.split(/,\s+/);

  var lat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
  var lon = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;

  if (
    args[0] &&
    args[1] &&
    lat.test(args[0].trim()) == true &&
    lon.test(args[1].trim()) == true
  ) {
    return '';
  } else {
    return 'validator.gps';
  }
};

const aValidator = {
  required,
  length,
  minLength,
  maxLength,
  email,
  alphai18n,
  alphaUSNumeric,
  pattern,
  alphaUS,
  number,
  date,
  password,
  Int,
  IntRange,
  daysLimitFuture,
  daysLimitOld,
  daysLimitOldToday,
  valPhone,
  minValue,
  validUUID,
  gps,
};

const commonValidator = (
  value,
  index,
  props,
  validator = '',
  validator2 = '',
  validator3 = ''
) => {
  if (!aValidator.hasOwnProperty(validator)) {
    return '* Validator not found ' + validator;
  }
  ////console.log('validitor found:'+validator);
  let resultVal;
  if (validator === 'valPhone') {
    // special fields send props:
    resultVal = aValidator[validator](
      value,
      index,
      props,
      validator2,
      validator3
    );
  } else {
    resultVal = aValidator[validator](value, validator2, validator3);
  }
  let result;
  if (typeof resultVal === 'string' && resultVal.includes('::')) {
    // has customized message error
    result = resultVal;
  } else if (resultVal) {
    // has no customize, take parameters like response
    result = `validator.${validator}::${validator2}::${validator3}`;
  } else {
    // validator passed test, all is ok
    result = '';
  }
  return result;
};

module.exports = {
  valPhone,
  aValidator,
  date,
  commonValidator,
  validUUID,
};
